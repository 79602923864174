var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseModal',{staticClass:"operation-modal",attrs:{"data-testid":"custom-operation-modal","is-open":_vm.isOpen,"size":"md"},on:{"close":_vm.emitClose},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CustomOperationNavBar',{attrs:{"title":"Operação personalizada","show-back-button":_vm.showBackButton},on:{"change-step":_vm.handleChangeStep,"close":_vm.emitClose}})]},proxy:true},{key:"content",fn:function(){return [_c('ModalStepTransition',[(_vm.currentStep === _vm.CustomOperationSteps.Loading)?_c('CustomOperationLoading',{key:"operation-loading"}):(_vm.currentStep === _vm.CustomOperationSteps.Error)?_c('CustomOperationError',{key:"operation-error",attrs:{"error-message":_vm.errorMessage},on:{"on-retry":_vm.handleOnRetry}}):(_vm.currentStep === _vm.CustomOperationSteps.OperationDetails)?_c('CustomOperationDetails',{key:"operation-details",attrs:{"operation":_vm.operation},on:{"change-step":_vm.handleChangeStep}}):(
          _vm.currentStep === _vm.CustomOperationSteps.InstrumentVariationHelperText
        )?_c('CustomOperationHelperText',{key:"instrument-variation",attrs:{"title":_vm.HelperTexts.instrumentVariation.title,"description":_vm.HelperTexts.instrumentVariation.description}}):(
          _vm.currentStep === _vm.CustomOperationSteps.OperationVariationHelperText
        )?_c('CustomOperationHelperText',{key:"operation-variation",attrs:{"title":_vm.HelperTexts.operationVariation.title,"description":_vm.HelperTexts.operationVariation.description}}):(
          _vm.currentStep === _vm.CustomOperationSteps.TotalEarningsHelperText
        )?_c('CustomOperationHelperText',{key:"total-earnings",attrs:{"title":_vm.HelperTexts.totalEarnings.title,"description":_vm.HelperTexts.totalEarnings.description}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }