
import { Component, Vue, InjectReactive } from 'vue-property-decorator';

import {
  CustomOperationsList,
  CustomOperationsOption
} from '@/modules/trade/components/CustomOperationsList';

import {
  trackCustomOperationsSelectChange,
  trackCustomOperationsRetryClick,
  trackCustomOperationRowClick
} from '@/modules/trade/views/Instrument/trackers';

import {
  getCustomOperationsSummary,
  CustomOperationSummary
} from '@/modules/trade/services/custom-operations';
import { isBFFError } from '@/modules/common/helpers/bff';

@Component({
  components: {
    CustomOperationsList
  }
})
export default class CustomOperationsSection extends Vue {
  operations: CustomOperationSummary[] = [];
  loading = true;
  error = false;
  errorMessage = '';

  @InjectReactive('ticker')
  readonly ticker!: string;

  created() {
    this.fetchCustomOperations();
  }

  get hasOperations() {
    return this.operations.length > 0;
  }

  get shouldRenderCustomOperationsList() {
    return this.hasOperations || this.loading || this.error;
  }

  async fetchCustomOperations() {
    try {
      this.loading = true;
      this.error = false;
      const symbol = this.ticker;

      const { operations } = await getCustomOperationsSummary(symbol);

      this.operations = operations;
    } catch (e) {
      let errorMessage = 'Não foi possível carregar as operações.';
      if (isBFFError(e)) {
        errorMessage = e.error.message.formatted;
      }
      this.errorMessage = errorMessage;
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  handleOnSelect(option: CustomOperationsOption) {
    trackCustomOperationsSelectChange(option.label);
  }

  handleOnRetryList() {
    trackCustomOperationsRetryClick();
    this.fetchCustomOperations();
  }

  handleOnCustomOperationRowClick(operation: CustomOperationSummary) {
    trackCustomOperationRowClick(operation.instrument.symbol);
  }
}
