
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  IConversationQuestionButton,
  IConversationQuestionInput
} from '@/modules/conversation/services/conversation';
import { MapPlaceholder } from './map-placeholder';
import {
  ConversationButtonInput,
  ConversationNumberInput,
  ConversationCurrencyInput,
  ConversationStringInput,
  ConversationMaskInput
} from '@/modules/conversation/components/inputs';

@Component
export default class ConversationInputs extends Vue {
  @Prop({ type: Object, required: false })
  public input!: IConversationQuestionInput;

  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  public buttons?: IConversationQuestionButton[];

  @Prop({ type: Boolean, default: false })
  public focusInput!: boolean;

  @Prop({ type: Boolean, default: false })
  public isHidden!: boolean;

  @Prop({ type: Boolean, required: false })
  public disableButtons?: boolean;

  @Prop({ type: Boolean, required: false })
  public isProcessing?: boolean;

  @Prop({ type: String, default: '', required: false })
  public questionId!: string;

  private selectedQuickMessage = '';

  private getSubcomponentType() {
    if (this.input) {
      const mask = this.input.mask;
      this.input.placeholder = !this.input.placeholder
        ? MapPlaceholder.get(this.questionId)
        : this.input.placeholder;

      switch (this.input.type) {
        case 'string':
          if (mask === 'date') {
            return ConversationMaskInput;
          } else {
            return ConversationStringInput;
          }
        case 'number':
          if (mask === 'integer') {
            return ConversationNumberInput;
          } else {
            return ConversationCurrencyInput;
          }
      }
    } else if (this.buttons) {
      return ConversationButtonInput;
    }
  }

  private selectQuickMessage(value: string) {
    this.selectedQuickMessage = value;
  }

  private answerMessage(inputData: string) {
    const el: HTMLElement | null = document.querySelector(':focus');
    if (el) el.blur();

    this.selectedQuickMessage = '';

    inputData = String(inputData);
    this.answer(inputData);
  }

  @Emit()
  private answer(value: string) {
    return value;
  }
}
