
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({ components: { BaseText } })
export default class ConversationDisclaimerMessage extends Vue {
  @Prop({ type: String, default: '' })
  public text!: string;

  readonly colors = NebraskaColors;
}
