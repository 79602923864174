
import { Component, Vue } from 'vue-property-decorator';
import { Heading, DataTable, Pagination } from '@warrenbrasil/nebraska-web';
import { FutureTransactionsFilters } from './components/FutureTransactionsFilters';
import { StatementEmpty } from '@/modules/transaction/views/Transactions/components/StatementEmpty';
import { StatementError } from '@/modules/transaction/views/Transactions/components/StatementError';
import { ReceiptModal } from '@/modules/transaction/components/ReceiptModal';

import { columns } from './helpers/tableHelper';

import { useDashStore } from '@/modules/common/store/DashModule';
import {
  getUpcomingTransfers,
  IStatementPagination
} from '@/modules/transaction/services/unified-account';
import { IFiltersValue, IStatementTransactions } from '../StatementList/types';
import * as trackEvents from '@/modules/transaction/views/Transactions/track-events';

@Component({
  components: {
    Heading,
    Pagination,
    FutureTransactionsFilters,
    DataTable,
    StatementEmpty,
    StatementError,
    ReceiptModal
  }
})
export default class FutureTransactions extends Vue {
  private readonly dashModule = useDashStore();

  transactionId? = '';
  isLoading = false;
  hasAppliedFilters = false;
  isReceiptModalOpen = false;
  isEmpty = false;
  hasError = false;
  pagination: IStatementPagination | null = null;
  filters = {
    page: 1
  };

  get showMoney() {
    return this.dashModule?.showMoney;
  }

  get computedClass() {
    return this.showMoney ? '' : 'hidden-fields';
  }

  get isDisabled() {
    return (
      this.isLoading ||
      (this.isEmpty && !this.hasAppliedFilters) ||
      this.hasError
    );
  }

  get columns() {
    return columns;
  }

  get showPagination() {
    return this.pagination && !this.isLoading && this.pagination.totalPages > 1;
  }

  async getFutureTransactions() {
    this.hasError = false;
    this.isLoading = true;
    try {
      const data = await getUpcomingTransfers(this.filters);
      this.pagination = data.pagination;
      this.isEmpty = data.items.length === 0;
      return data.items;
    } catch (e) {
      this.hasError = true;
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  loadError() {
    trackEvents.trackFutureTransactionsErrorLoad();
  }

  changeFilters(data: { hasAppliedFilters: boolean; filters: IFiltersValue }) {
    this.hasError = false;
    this.isEmpty = false;
    this.hasAppliedFilters = data.hasAppliedFilters;
    this.filters = { ...this.filters, page: 1, ...data.filters };
  }

  changePage(value: number) {
    this.filters = { ...this.filters, page: value };
  }

  handleClick(row: IStatementTransactions) {
    this.changeModalStatus();
    trackEvents.trackSelectMovimentationClick();
    this.transactionId = row.id;
  }

  changeModalStatus() {
    this.isReceiptModalOpen = !this.isReceiptModalOpen;
  }
}
