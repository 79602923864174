import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { StatementAvatarCell } from '@/modules/transaction/views/Transactions/components/StatementAvatarCell';

export const columns: DataTableProps.Column[] = [
  {
    component: StatementAvatarCell,
    width: '4.11%',
    getter: ({ icon }) => ({
      iconName: icon,
      type: 'Future'
    })
  },
  {
    label: 'Movimentação',
    width: '46.25%',
    getter: ({ name, description }) => ({
      text: name,
      description
    })
  },
  {
    label: 'Data',
    width: '23.11%',
    align: DataTableProps.Align.Right,
    getter: 'date'
  },
  {
    label: 'Valor',
    width: '23.11%',
    align: DataTableProps.Align.Right,
    getter: ({ value }) => ({
      text: value.formatted
    })
  },
  {
    width: '3.42%',
    type: 'icon',
    getter: () => ({
      icon: 'EA0170',
      ariaLabel: 'Detalhes da transação'
    })
  }
];
