import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEventReceipt = GtmTracker.trackerWithDefaultProps({
  page_screen: 'all_movimentations_movimentation_details',
  digital_experience: 'investor',
  experience_section: 'home'
});

export function trackReceiptLoad(target: string) {
  trackAmplitudeEventReceipt({
    event_type: 'load',
    event_name: 'view',
    event_target: target
  });
}
