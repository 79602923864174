
import { Component, Emit, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import validate from './validate';
import { IConversationQuestionInput } from '@/modules/conversation/services/conversation';

@Component
export default class ConversationInputBase extends Vue {
  @Prop({ type: Object })
  public input!: IConversationQuestionInput;

  @Prop({ type: String, default: 'Ok' })
  public buttonLabel?: string;

  @Prop({ type: Boolean, default: false })
  public focusInput?: boolean;

  @Prop({ type: String, default: '' })
  public overrideInputValue!: string;

  @Prop({ type: Boolean, default: false })
  public isProcessing?: boolean;

  protected isInputInvalid = true;
  protected inputValue: string | number = '';

  @Ref() private readonly inputElement!: HTMLElement | Vue;

  protected mounted() {
    this.focusElement();
    this.inputChanged();
  }

  @Watch('input', { immediate: true })
  protected inputChanged() {
    if (this.input && this.input.defaultValue) {
      this.inputValue = this.input.defaultValue;
      this.enableSubmitButton();
    }
    this.$forceUpdate();
  }

  protected async enableSubmitButton() {
    const errors: any = await this.validate();
    if (errors && errors[this.input.mask]) {
      this.isInputInvalid = true;

      return;
    }
    this.isInputInvalid = false;
  }

  protected async validate() {
    return await validate({ [this.input.mask]: this.inputValue });
  }

  protected submitForm() {
    this.answered();
    this.inputValue = '';
    this.enableSubmitButton();
  }

  @Emit()
  protected answered() {
    return this.inputValue;
  }

  @Watch('overrideInputValue')
  protected overrideInput() {
    this.inputValue = this.overrideInputValue;
    this.enableSubmitButton();
  }

  @Watch('focusInput')
  private focusElement() {
    if (this.focusInput && this.inputElement) {
      if (this.isVUE(this.inputElement)) {
        ((this.inputElement as Vue).$el as HTMLInputElement).focus();
      } else {
        (this.inputElement as HTMLInputElement).focus();
      }
    }
  }

  private isVUE(object: any): object is Vue {
    return '$el' in object;
  }
}
