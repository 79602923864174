
import { Component } from 'vue-property-decorator';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { mixins } from 'vue-class-component';
import { ConversationInputBase } from '../ConversationInputBase';

@Component({
  components: { ButtonPrimary }
})
export default class ConversationNumberInput extends mixins(
  ConversationInputBase
) {
  public get innerPlaceholder() {
    return this.input?.placeholder;
  }
}
