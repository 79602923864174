
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import {
  Modal,
  Row,
  Heading,
  Tag,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';

import { IContractDetails } from '@/modules/trade/services/contracts-btc';
import { BtcDetails } from './components/BtcDetails';

@Component({
  components: {
    Modal,
    Row,
    Heading,
    Tag,
    BtcDetails,
    ButtonSecondary
  }
})
export default class ContractModal extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isOpen!: boolean;

  @Prop({ type: Object, required: true })
  readonly contractsData!: IContractDetails;

  @Emit('emit-close') emitClose() {}

  @Emit('download-contract') emitDownloadContract() {}
}
