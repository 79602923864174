
import { Component, Vue, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  ContextColorProvider,
  ActionableHelperView,
  Heading,
  NavBar,
  RoundedIcon
} from '@warrenbrasil/nebraska-web';

import { Conversation } from '@/modules/conversation/components/Conversation';
import { BaseCard } from '@/modules/common/components/cards/BaseCard';
import { DepositTransaction } from './components/DepositTransaction';
import { WithdrawTransaction } from './components/WithdrawTransaction';
import { ConfirmationWithdrawTransaction } from './components/ConfirmationWithdrawTransaction';
import { InternalMovementTransaction } from './components/InternalMovementTransaction';
import { DepositInvoiceTransaction } from './components/DepositInvoiceTransaction';
import { formatDate } from '@/modules/common/helpers/date';
import {
  getBrandInitial,
  userCanPerform,
  Operation
} from '@/modules/common/services/partner';
import { TransactionModule } from '@/store/modules/TransactionModule';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BankData } from './components/BankData';
import { GtmTracker } from '@/modules/common/services/analytics';

import { ICustomer } from '@/types/models';
import { IBankAccount } from '@/types/models/Customer/BankAccount';
import {
  IConversationAnswerData,
  IConversationQuestion,
  IConversationQuestionButton,
  IConversationParsedPortfolios
} from '@/modules/conversation/services/conversation';
import {
  IConversationDataTransaction,
  IConversationPortfolio
} from '@/types/models/Transaction';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { ECameFromPath } from '@/modules/transaction/views/InvestmentTransaction/types';
import {
  trackDepositByAction,
  trackAccountByAction
} from '@/modules/home/helpers/tracking';

import { EChoice } from './types';

const answerData: IConversationDataTransaction = {
  id: 'initial',
  context: 'transactions',
  subcontext: '',
  portfolio: '',
  answers: {},
  portfolios: []
};

const emptyQuestion: IConversationQuestion = {
  id: '',
  buttons: [],
  inputs: [],
  radios: [],
  checkbox: [],
  messages: [],
  responses: [],
  clearMessages: true
};

enum TransactionType {
  DEPOSIT = 'deposit_final',
  INVOICE = 'invoice_final',
  WITHDRAW = 'withdraw_final',
  TRANSFER = 'transfer_final'
}

const DEPOSITS_AMOUNT = 'deposits_amount';
const QUESTION_PORTFOLIO = 'question_portfolio';
const TRANSFER_TO_PORTFOLIO = 'transfer_to_portfolio';
const OPERATION_CHOICE_QUESTION = 'question_choice';
const WITHDRAW_BANK_QUESTION = 'withdraw_bank';
const WITHDRAW_BY_PRODUCT_QUESTION = 'question_product_withdraw';
const CASH_MODULE = 'cash';
const TRADE_MODULE = 'trade';
const WEALTH_PORTFOLIO_MODULE = 'wealthPortfolio';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    Conversation,
    ContextColorProvider,
    NavBar,
    RoundedIcon,
    DepositTransaction,
    WithdrawTransaction,
    ConfirmationWithdrawTransaction,
    ActionableHelperView,
    InternalMovementTransaction,
    DepositInvoiceTransaction,
    BaseCard,
    BankData,
    Heading
  }
})
export default class NewTransactionsView extends Vue {
  private answerData: IConversationDataTransaction = answerData;
  private questionData: IConversationQuestion = emptyQuestion;
  private hasFinished = false;
  private isError = false;
  private portfolios: IConversationQuestionButton[] = [];
  private conversationSlotQuestions: object[] = [];
  private slotAnswer = '';
  private isRespondingQuestion = false;
  private NebraskaColors = NebraskaColors;

  private displayBankAccount = false;

  @Ref()
  private readonly conversation!: Conversation;

  @customerModule.Getter('getCustomer')
  private customer!: ICustomer;

  private get parsedPortfolios(): IConversationParsedPortfolios[] {
    return this.portfolios.map(button => {
      const walletName = button.label.title;
      const isUserWallet = walletName !== 'Trade';

      return { ...button, isUserWallet };
    });
  }

  private get hasPortfolios() {
    return !!this.$route.query.subcontext;
  }

  private get isLegacyInvestment() {
    const paramName = 'legacy-investment-account';
    return Boolean(this.$route.query[paramName]);
  }

  private get isCashModule() {
    return this.module === CASH_MODULE;
  }

  private get showDepositButton() {
    return this.$route.query.showDepositButton === 'true';
  }

  async created() {
    TransactionModule.clearStateHistory();

    this.answerData = {
      ...this.answerData,
      portfolio: this.$route.params.portfolio || '',
      subcontext: (this.$route.query.subcontext as string) || ''
    };

    if (this.$route.query.choice) {
      if (this.$route.query.choice === EChoice.WithdrawByCart) {
        return await this.handleMessageData({
          value: 'withdrawals',
          messageId: 'question_choice'
        });
      }
      this.answerData.answers = {
        question_choice: this.$route.query.choice as string
      };

      if (
        this.userCameFromTrade ||
        (this.isCashModule && !this.hasPortfolios)
      ) {
        this.answerData.id = OPERATION_CHOICE_QUESTION;
      }
    } else {
      this.answerData.answers = {};
    }

    TransactionModule.registerStateHistory({
      conversationAnswer: this.answerData,
      conversationQuestion: this.questionData
    });

    await this.updateMessage(this.answerData);
  }

  handleUserResponses(answer: string) {
    if (
      this.isAnswerWealthDepositButton(answer) &&
      this.isNewTransactionFlowEnabled
    ) {
      this.redirectToNewInvestmentFlow(this.$route.query.portfolio as string);
    }

    return [];
  }

  private isAnswerWealthDepositButton(answer: string) {
    return this.module === WEALTH_PORTFOLIO_MODULE && answer === 'deposits';
  }

  private get isCashSubcontext() {
    return this.$route.query.subcontext === 'cash';
  }

  private validateDepositConfirmation({ messageId }: IConversationAnswerData) {
    return this.isCashModule && messageId === 'input_deposits_amount';
  }

  private validateWithdrawConfirmation({ messageId }: IConversationAnswerData) {
    return this.isCashSubcontext && messageId === 'withdraw_amount';
  }

  private async handleMessageData(data: IConversationAnswerData) {
    const canTrackDeposit = this.validateDepositConfirmation(data);
    if (canTrackDeposit)
      trackDepositByAction('Depósito por boleto \\ Valor Boleto OK');

    const canTrackWithdraw = this.validateWithdrawConfirmation(data);
    if (canTrackWithdraw) trackAccountByAction('Transferir \\ OK');

    if (data) if (data.value === 'start_scanner') this.paymentRedirect();
    if (data.value === 'withdraw_add_bank_account') {
      this.displayBankAccount = true;
      return;
    }
    if (data.value === 'update_to_regulation') {
      return this.$router.push({ name: 'user-account' });
    }
    try {
      this.hasFinished = false;
      this.isError = false;
      this.answerData = this.createAnswer(data);

      await this.updateMessage(this.answerData);

      this.handleTrackData();
    } catch (error) {
      this.isError = true;
    }
  }

  async registeredBankAccount(bankAccount: IBankAccount) {
    try {
      this.hasFinished = false;
      this.isError = false;
      this.answerData = this.createAnswer({
        value: bankAccount._id,
        messageId: 'withdraw_bank'
      });

      this.displayBankAccount = false;
      this.$forceUpdate();
      await this.updateMessage(this.answerData);
    } catch (error) {
      this.isError = true;
    }
  }

  private handleTrackData() {
    if (!this.userCameFromTrade) return;

    if (this.questionData.id === DEPOSITS_AMOUNT) {
      GtmTracker.trackEvent({
        category: 'Trade',
        label: 'Transações',
        action: 'Depósito'
      });
    }

    if (this.questionData.id === TRANSFER_TO_PORTFOLIO) {
      GtmTracker.trackEvent({
        category: 'Trade',
        label: 'Transações',
        action: 'Movimentar Internamente'
      });
    }
  }

  private createAnswer(
    answer: IConversationAnswerData
  ): IConversationDataTransaction {
    const shouldAddPortfolio =
      this.hasPortfolios && answer.messageId === DEPOSITS_AMOUNT;
    return {
      ...this.answerData,
      id: answer.messageId,
      answers: {
        ...this.answerData.answers,
        [answer.messageId]: this.formatValue(answer)
      },
      portfolios: shouldAddPortfolio
        ? this.addPortfolio(answer)
        : this.answerData.portfolios
    };
  }

  private addPortfolio(
    answer: IConversationAnswerData
  ): IConversationPortfolio[] {
    let portfolios = this.answerData.portfolios || [];
    const portfolioId = this.answerData.answers.question_portfolio || '';

    // if initial value is not valid, remove portfolio from list
    portfolios = portfolios.filter(
      (portf: IConversationPortfolio) => portf._id !== portfolioId
    );

    const portfolio: IConversationPortfolio = {
      _id: portfolioId,
      name: this.answerData.answers.portfolio_name || '',
      amount: (answer.value as string) || 0
    };

    return [...portfolios, portfolio];
  }

  private async updateMessage(data: IConversationDataTransaction) {
    try {
      const resp = await TransactionModule.fetchNextQuestion(data);
      this.questionData = this.parseQuestionData(resp);
    } catch (error) {
      this.isError = true;
    }
  }

  private parseQuestionData(questionData: IConversationQuestion) {
    if (questionData.id === OPERATION_CHOICE_QUESTION)
      return this.handleOperationChoice(questionData);
    if (questionData.id === WITHDRAW_BANK_QUESTION)
      return this.handleBankQuestion(questionData);
    if (questionData.id === WITHDRAW_BY_PRODUCT_QUESTION)
      return this.handleWithdrawByProductQuestion(questionData);

    if (this.isShowPortfolioCards(questionData)) {
      this.conversationSlotQuestions = [
        ...this.conversationSlotQuestions,
        { id: questionData.id, hasDefaultAnswerButton: false }
      ];
      this.portfolios = questionData.buttons;

      return { ...questionData, buttons: [] };
    }

    return questionData;
  }

  private formatValue(answer: IConversationAnswerData) {
    const isAnswerValueString = typeof answer.value === 'string';

    if (answer.messageId === 'withdraw_schedule_at' && isAnswerValueString) {
      return formatDate(answer.value as string, 'DD/MM/YYYY', 'YYYY-MM-DD');
    } else if (answer.messageId === 'transfer_amount' && isAnswerValueString) {
      return Number(answer.value);
    }

    return answer.value;
  }

  private portfolioSlotInputResponse(
    portfolio: IConversationParsedPortfolios,
    questionId: string
  ) {
    const { isUserWallet, value: portfolioId } = portfolio;
    const isNotInternalTransfer = questionId !== TRANSFER_TO_PORTFOLIO;

    if (
      this.isNewTransactionFlowEnabled &&
      (isNotInternalTransfer || isUserWallet) &&
      !this.userCameFromTrade &&
      !this.isLegacyInvestment
    ) {
      this.redirectToNewInvestmentFlow(portfolioId);
    } else {
      this.slotAnswer = portfolioId;
    }
  }

  private get isWithdrawalConversationEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.WITHDRAWAL_CONVERSATION);
  }

  private get isNewTransactionFlowEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.ENHANCE_TRANSACTIONS_BBB);
  }

  private get isFinishDepositConversation() {
    return this.questionData.id === TransactionType.DEPOSIT;
  }

  private get isFinishWithdrawConversation() {
    return this.questionData.id === TransactionType.WITHDRAW;
  }

  private get isFinishInternalMovementConversation() {
    return this.questionData.id === TransactionType.TRANSFER;
  }

  private get isFinishInvoiceConversation() {
    return this.questionData.id === TransactionType.INVOICE;
  }

  private isShowPortfolioCards(questionData: IConversationQuestion) {
    return (
      (questionData.id === QUESTION_PORTFOLIO ||
        questionData.id === TRANSFER_TO_PORTFOLIO) &&
      questionData.buttons.every(btn => btn.style === 'card')
    );
  }

  private get userCameFromTrade() {
    return this.module === TRADE_MODULE;
  }

  private reload() {
    window.location.reload();
  }

  private paymentRedirect() {
    const portfolio = this.$route.params.portfolio || '';
    this.$router.push({ name: 'payment', params: { portfolio } });
  }

  async onBackClick() {
    const hasPreviousStoreState = await TransactionModule.rollbackQuestion();

    if (
      hasPreviousStoreState.conversationQuestion.id.length &&
      hasPreviousStoreState.conversationQuestion.id !== this.questionData.id
    ) {
      this.conversation.clearMessages();
      this.answerData = hasPreviousStoreState.conversationAnswer;
      this.questionData = this.parseQuestionData(
        hasPreviousStoreState.conversationQuestion
      );
    } else {
      this.onBackTo();
    }
  }

  public invoiceFinish() {
    if (this.isCashModule)
      trackDepositByAction(
        'Depósito por boleto \\ Valor Boleto OK \\ Finalizar'
      );
    this.onBackTo();
  }

  public withdrawFinish() {
    if (this.isCashSubcontext)
      trackAccountByAction('Transferir \\ OK \\ Transferir');
    this.onBackTo();
  }

  private onBackTo() {
    const originPortfolioId = (this.$route.query.portfolio as string) ?? '';

    this.$router.push({
      name: this.backToRoute,
      params: {
        id: originPortfolioId
      }
    });
  }

  private get module() {
    return this.$route.query.module as string;
  }

  private get backToRoute() {
    const backTo = this.$route.query.backTo as string;
    return backTo || this.module || 'home';
  }

  private handleOperationChoice(questionData: IConversationQuestion) {
    let buttons = questionData.buttons.filter(button =>
      userCanPerform(button.value as Operation)
    );

    // Verify if partner can withdraw from the cash account
    if (
      this.module === 'cash' &&
      !userCanPerform(Operation.WithdrawToExternalAccount)
    ) {
      buttons = buttons.filter(button => button.value !== Operation.Withdraw);
    }

    return { ...questionData, buttons };
  }

  private handleBankQuestion(questionData: IConversationQuestion) {
    const buttons = questionData.buttons.filter(
      button => button.label.title === 'Conta Digital'
    );
    return userCanPerform(Operation.WithdrawToExternalAccount)
      ? questionData
      : { ...questionData, buttons };
  }

  private handleWithdrawByProductQuestion(questionData: IConversationQuestion) {
    const buttons = questionData.buttons.filter(
      button => button.value === 'product'
    );
    return userCanPerform(Operation.WidthdrawPortfolio)
      ? questionData
      : { ...questionData, buttons };
  }

  get trademarkInitial() {
    return getBrandInitial(this.customer.partnerInfos);
  }

  private redirectToNewInvestmentFlow(portfolioId: string) {
    this.$router.push({
      name: 'investment-transaction',
      params: { portfolio: portfolioId },
      query: { from: ECameFromPath.Conversation }
    });
  }

  destroyed() {
    TransactionModule.clearStateHistory();
  }
}
