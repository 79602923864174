
import { Component, Emit, Vue } from 'vue-property-decorator';

import { HelperView } from '@warrenbrasil/nebraska-web';

@Component({
  components: { HelperView }
})
export default class ReceiptError extends Vue {
  @Emit('reload')
  reload() {}
}
