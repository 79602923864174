
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

import {
  Modal,
  HeadingDisplay,
  ContentDisplay,
  ListDisplay,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { Portal } from '@/modules/common/components/Portal';
import { ReceiptLoader } from './components/ReceiptLoader';
import { ReceiptError } from './components/ReceiptError';

import {
  addBodyScrollWithScrollTo,
  removeBodyScroll
} from '@/modules/common/helpers/browser';
import {
  getVoucher,
  IVoucherResponse,
  IVoucherRequest
} from '@/modules/transaction/services/unified-account';
import * as trackEvents from './track-events';

@Component({
  components: {
    Modal,
    HeadingDisplay,
    ContentDisplay,
    ListDisplay,
    ButtonSecondary,
    Portal,
    ReceiptLoader,
    ReceiptError
  }
})
export default class ReceiptModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: String, required: false, default: '' })
  readonly transactionId!: string;

  @Prop({ type: Object, required: false })
  readonly filters?: IVoucherRequest;

  @Prop({ type: Boolean, default: null })
  readonly isUpcomingTransfer?: boolean;

  @Watch('isOpen', { immediate: true })
  public async toggleBodyScroll(isOpen: boolean) {
    if (isOpen) {
      removeBodyScroll();
      await this.getVoucher();
      trackEvents.trackReceiptLoad(this.modalData?.title || '');
    } else {
      addBodyScrollWithScrollTo();
    }
  }

  @Emit('close')
  close() {}

  modalData: Reactive<IVoucherResponse> = null;
  isLoading = false;
  hasError = false;

  async getVoucher() {
    this.hasError = false;
    this.isLoading = true;
    try {
      const data = await getVoucher(
        this.transactionId,
        this.filters,
        this.isUpcomingTransfer
      );
      this.modalData = data;
    } catch (e) {
      console.error(e);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  goToPage() {
    window.open(this.modalData?.link);
  }
}
