
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  FilterSelect,
  ButtonSecondary,
  Link
} from '@warrenbrasil/nebraska-web';
import { IFiltersValue } from '@/modules/transaction/views/Transactions/components/StatementList/types';

@Component({
  components: { FilterSelect, ButtonSecondary, Link }
})
export default class FutureTransactionsFilters extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  @Emit('change')
  private emitChange() {
    return {
      filters: this.filtersValue,
      hasAppliedFilters: this.hasAppliedFilters
    };
  }

  public filtersValue: IFiltersValue = {
    period: '',
    movementType: '',
    module: ''
  };

  datePeriodsOptions = [
    { value: 'SEVEN_DAYS', label: '7 dias' },
    { value: 'THIRTY_DAYS', label: '30 dias' },
    { value: 'NINETY_DAYS', label: '90 dias' },
    { value: '', label: 'Tudo' }
  ];

  typesOptions = [
    { value: 'INPUTS', label: 'Entradas' },
    { value: 'OUTPUTS', label: 'Saídas' },
    { value: '', label: 'Tudo' }
  ];

  modulesOptions = [
    { value: 'TRADE', label: 'Bolsa' },
    { value: 'WEALTH', label: 'Carteiras' },
    { value: '', label: 'Tudo' }
  ];

  get hasAppliedFilters() {
    return Object.values(this.filtersValue).some(value => !!value);
  }

  clearFilters() {
    this.filtersValue = {
      period: '',
      movementType: '',
      module: ''
    };

    this.emitChange();
  }

  handleChange() {
    this.emitChange();
  }
}
