
import { BaseText } from '@warrenbrasil/nebraska-web';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: { BaseText } })
export default class BaseBadge extends Vue {
  @Prop(Boolean) public error!: boolean;
  @Prop(Boolean) public success!: boolean;
  @Prop(Boolean) public positive!: boolean;
  @Prop(Boolean) public negative!: boolean;
  @Prop(Boolean) public black!: boolean;
  @Prop(Boolean) public blue!: boolean;
  @Prop(Boolean) public customTheme!: boolean;
  @Prop(String) public customBackgroundColor!: string;
  @Prop(String) public customColor!: string;
  @Prop(String) public fontSize!: string;
}
