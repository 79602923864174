
import { Component, Prop, Emit } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { RouteMixin } from '@/modules/common/mixins/route';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { Row, BaseText, ButtonPrimary } from '@warrenbrasil/nebraska-web';
import {
  trackWithdrawActionClick,
  trackWithdrawLiquidateClick
} from '@/modules/trade/views/TradeView/trackers';

import { Balances } from '@/modules/trade/services/account';

@Component({
  components: {
    Row,
    BackButton,
    BaseText,
    ButtonPrimary
  }
})
export default class AvailableWithdrawal extends mixins(RouteMixin) {
  @Prop({ type: Object, required: true })
  readonly balances!: Balances;

  @Emit('set-general')
  public emitSetGeneral() {}

  @Emit('set-liquidate')
  public emitSetLiquidate() {}

  public get todayBalance() {
    return this.balances.todayBalance?.formatted;
  }

  public get toLiquidateBalance() {
    return this.balances.toLiquidateBalance?.formatted;
  }

  public get withdrawalBalance() {
    return this.balances.withdrawalBalance?.formatted;
  }

  public liquidateAction() {
    trackWithdrawLiquidateClick();
    this.emitSetLiquidate();
  }

  public withdrawlAction() {
    const { portfolioApiId } = this.balances;
    trackWithdrawActionClick();
    this.goToTransaction(
      `${portfolioApiId}?subcontext=trade&module=trade&choice=transfer`
    );
  }
}
