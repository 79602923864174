
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Row, BaseText } from '@warrenbrasil/nebraska-web';
import { IContracts } from '@/modules/trade/services/contracts-btc';

@Component({
  components: {
    Row,
    BaseText
  }
})
export default class BtcDetails extends Vue {
  @Prop({ type: Object, required: true })
  readonly detail!: IContracts;
}
