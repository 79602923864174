import { Route } from 'vue-router';
import { PortfolioModule } from '@/store/modules/PortfolioModule';

export function beforeEnterInvestmentFlow(
  _to: Route,
  _from: Route,
  next: () => void
) {
  if (PortfolioModule.wasFetchedOnce) return next();

  PortfolioModule.fetchPortfolios();
  next();
}
