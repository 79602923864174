
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TypeWriter } from '@/modules/common/components/TypeWriter';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RoundedIcon, BaseIcon } from '@warrenbrasil/nebraska-web';

@Component({
  components: { RoundedIcon, BaseIcon, TypeWriter }
})
export default class ConversationStringMessage extends Vue {
  @Prop({ type: String, default: '' })
  public text!: string;

  @Prop({ type: Boolean })
  public isLeftMessage?: boolean;

  @Prop({ type: Boolean, default: false })
  public skip?: boolean;

  @Prop({ type: String, default: '' })
  public iconText!: string;

  private NebraskaColors = NebraskaColors;

  @Emit()
  private finished() {}
}
