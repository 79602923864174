
import { Component, Emit, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
import { IConversationQuestionSelectValue } from '@/modules/conversation/services/conversation';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Input as NebraskaInput, BaseIcon } from '@warrenbrasil/nebraska-web';

@Component({
  components: { NebraskaInput, BaseIcon }
})
export default class ConversationSelectMessage extends Vue {
  @Prop(Array)
  public selectArray!: IConversationQuestionSelectValue[];

  @Prop(String)
  public placeholderText?: string;

  @Prop(String)
  public defaultValue?: string;

  @Prop(Object)
  public searchReturnEmptyElement?: IConversationQuestionSelectValue;

  @Ref()
  private readonly emailAutoComplete!: Vue;

  @Prop(Boolean)
  private hasError?: boolean;

  private searchArray?: IConversationQuestionSelectValue[];

  private selectedValue = '';
  private NebraskaColors = NebraskaColors;

  @Watch('selectArray')
  private async created() {
    if (this.defaultValue) {
      this.selectedValue = this.defaultValue;
    }

    this.selectArray = this.selectArray.sort(
      (
        a: IConversationQuestionSelectValue,
        b: IConversationQuestionSelectValue
      ) => {
        if (a.label.title < b.label.title) {
          return -1;
        }
        if (a.label.title > b.label.title) {
          return 1;
        }
        return 0;
      }
    );
  }

  private setSelectedValue(value: string) {
    this.selectedValue = value;

    if (this.selectedValue) {
      this.searchArray = [];

      this.selectArray.forEach(element => {
        if (element.label.title.toLowerCase().includes(value.toLowerCase())) {
          this.searchArray!.push(element);
        }
      });

      if (
        this.searchArray &&
        this.searchArray!.length === 0 &&
        this.searchReturnEmptyElement
      ) {
        this.searchArray.push(this.searchReturnEmptyElement);
      }
    }

    this.selected([]);

    if (
      this.searchReturnEmptyElement &&
      this.isEqualToSelectedValue(this.searchReturnEmptyElement.label.title)
    ) {
      this.selected([this.searchReturnEmptyElement!.value, value]);

      return;
    }

    this.selectArray.forEach(element => {
      if (this.isEqualToSelectedValue(element.label.title)) {
        this.selected([element.value, value]);
      }
    });
  }

  private get currentArrayInUsage() {
    return this.selectedValue ? this.searchArray : this.selectArray;
  }

  private isEqualToSelectedValue(element: string) {
    return element.toLowerCase() === this.selectedValue.toLowerCase();
  }

  @Emit()
  private selected(value: string[]): string[] {
    return value;
  }
}
