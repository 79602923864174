import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { Invoice } from './invoices-types';
import { IConversationQuestionTransaction } from '@/modules/conversation/services/conversation';

export default class InvoicesAPI extends BaseHttpClient {
  public createInvoice(
    data: IConversationQuestionTransaction
  ): Promise<Invoice> {
    return this.post<Invoice>('/api/v3/invoices/create', data);
  }

  public getInvoice(id: string): Promise<any> {
    return this.get<any>(`/api/v2/deposits/single/${id}`);
  }

  public cancelInvoice(id: string): Promise<any> {
    return this.put<any>(`/api/v2/invoices/single/${id}/cancel`);
  }
}
