import { render, staticRenderFns } from "./WealthViewActions.vue?vue&type=template&id=4bd54caf&scoped=true&"
import script from "./WealthViewActions.vue?vue&type=script&lang=ts&"
export * from "./WealthViewActions.vue?vue&type=script&lang=ts&"
import style0 from "./WealthViewActions.vue?vue&type=style&index=0&id=4bd54caf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd54caf",
  null
  
)

export default component.exports