
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  ButtonPrimary,
  Heading,
  BaseIcon,
  Row,
  BankAccount
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import {
  WithdrawsAPI,
  CreateWithdraw
} from '@/modules/transaction/services/withdraws';
import { GtmTracker } from '@/modules/common/services/analytics';

@Component({
  components: {
    Heading,
    Row,
    BankAccount,
    ButtonPrimary,
    BaseIcon
  },
  filters: { money }
})
export default class WithdrawTransaction extends Vue {
  @Prop({ type: Object, required: true })
  private withdraw!: CreateWithdraw;

  private isLoading = false;
  private NebraskaColors = NebraskaColors;

  private async handleCreateWithdraw() {
    try {
      const withdrawsApi = new WithdrawsAPI();

      this.isLoading = true;
      await withdrawsApi.createWithdrawal(this.withdraw);
      this.track();
      this.$emit('click-finished');
    } catch (error) {
      this.isLoading = false;
      this.$emit('error');
    }
  }

  private track() {
    GtmTracker.trackEvent({
      action: 'Withdrawal Created',
      category: '',
      label: ''
    });
  }

  private get agencyNumber() {
    return this.withdraw.accountAgency.split('-')[0];
  }
}
