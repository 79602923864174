
import { Component, Prop } from 'vue-property-decorator';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { mixins } from 'vue-class-component';
import { MatchMedia } from '@/modules/common/components/MatchMedia';
import { ConversationInputBase } from '../ConversationInputBase';
import { getString } from '@/modules/common/helpers/resources';

@Component({
  components: { ButtonPrimary, MatchMedia }
})
export default class ConversationStringInput extends mixins(
  ConversationInputBase
) {
  @Prop({ type: String, default: '' })
  public placeholder?: string;

  private type = '';
  private innerPlaceholder = '';

  created() {
    switch (this.input.mask) {
      case 'email':
        this.type = 'email';
        this.innerPlaceholder = getString(
          'suitability_input_email_placeholder'
        );
        break;
      case 'name':
        this.type = 'text';
        this.innerPlaceholder = getString('suitability_input_name_placeholder');
        break;
      case 'password':
        this.type = 'password';
        this.innerPlaceholder = getString(
          'suitability_input_password_placeholder'
        );
        break;
      default:
        this.type = 'text';
        if (this.placeholder) {
          this.innerPlaceholder = this.placeholder;
        }
        break;
    }
  }
}
