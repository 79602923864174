
/* eslint-disable new-cap */
import { Component, Emit, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import Mustache from 'mustache';
import VueScrollTo from 'vue-scrollto';
import {
  IConversationQuestionRadio,
  IConversationQuestionCheckbox,
  IConversationQuestionMessage,
  IConversationQuestionSelectValue,
  IConversationQuestionRow,
  IConversationQuestionMessageCard
} from '@/modules/conversation/services/conversation';

import {
  ConversationStringMessage,
  ConversationCardMessage,
  ConversationRadioMessage,
  ConversationCheckboxMessage,
  ConversationSelectMessage,
  ConversationAlertMessage,
  ConversationTableMessage,
  ConversationRowsMessage,
  ConversationDisclaimerMessage
} from '@/modules/conversation/components/messages';

@Component({
  components: {
    ConversationStringMessage
  }
})
export default class ConversationMessages extends Vue {
  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  public messages!: IConversationQuestionMessage[];

  @Prop({ type: String, default: 'Warren' })
  public username?: string;

  @Prop(String)
  public trademarkInitial?: string;

  @Prop(Boolean)
  public displayMessageLoader?: boolean;

  @Prop(String)
  public questionId?: string;

  @Prop()
  public radios?: IConversationQuestionRadio[];

  @Prop()
  public checkbox?: IConversationQuestionCheckbox[];

  @Prop()
  public selects?: IConversationQuestionSelectValue[];

  @Prop()
  public selectDefaultSearchEmptyElement?: IConversationQuestionSelectValue;

  @Prop()
  public rows?: IConversationQuestionRow[];

  // TODO: Can't use this prop as it is, since API message returns are typed as '{{ answer.something }}' and not '{{ something }}',
  // which prevents the use of the prop as it should have this `answer` object, so `answerObject` variable handles that.
  @Prop(Object)
  public userAnsweredQuestions!: any;

  private answerObject: any = {};

  // @ts-ignore
  private currentMessage: IConversationQuestionMessage;
  private currentMessageIndex = 0;
  private renderNextMessage = true;
  private skip = false;

  private deleteLastElementBeforeNextMessage = false;
  private stringELEM = Vue.extend(ConversationStringMessage);
  private cardELEM = Vue.extend(ConversationCardMessage);
  private radioELEM = Vue.extend(ConversationRadioMessage);
  private checkboxELEM = Vue.extend(ConversationCheckboxMessage);
  private selectELEM = Vue.extend(ConversationSelectMessage);
  private alertELEM = Vue.extend(ConversationAlertMessage);
  private tableELEM = Vue.extend(ConversationTableMessage);
  private rowELEM = Vue.extend(ConversationRowsMessage);
  private disclaimerELEM = Vue.extend(ConversationDisclaimerMessage);

  private cleaningMessagesHistory = false;

  private scrollAnimation?: () => void;

  // @ts-ignore
  private currentDisplayingElement: any = null;

  @Ref() private readonly conversation!: HTMLElement;

  public clearMessagesHistory(deleteAfterId?: string) {
    const messagesContainer = document.querySelector('.conversation-messages');

    if (this.currentDisplayingElement) {
      this.currentDisplayingElement.$off();
      this.renderNextMessage = true;
    }

    if (!deleteAfterId) {
      this.currentMessageIndex = 0;
    }

    if (messagesContainer) {
      let elementOfClassID;

      if (deleteAfterId) {
        elementOfClassID = document.getElementsByClassName(
          `messages_${deleteAfterId}`
        );
      }

      let lastMessageElement;

      if (elementOfClassID) {
        lastMessageElement =
          elementOfClassID[elementOfClassID.length - 1].nextSibling;
      } else {
        lastMessageElement = messagesContainer.lastElementChild;
      }

      while (lastMessageElement) {
        if (
          this.currentDisplayingElement &&
          lastMessageElement === this.currentDisplayingElement.$el
        ) {
          this.currentDisplayingElement = null;
        }
        messagesContainer.removeChild(lastMessageElement);
        lastMessageElement = messagesContainer.lastElementChild;
      }
    }
  }

  @Watch('userAnsweredQuestions', { deep: true })
  public getAnswers() {
    this.answerObject = {
      answers: {
        ...this.userAnsweredQuestions
      }
    };
  }

  public created() {
    window.addEventListener('keyup', this.skipMessages);
  }

  private destroyed() {
    window.removeEventListener('keyup', this.skipMessages);
  }

  private skipMessages(keyPressed?: KeyboardEvent) {
    if (this.currentMessageIndex < this.messages.length) {
      if (
        keyPressed &&
        keyPressed.type === 'keyup' &&
        keyPressed.key !== 'Enter'
      ) {
        return;
      }
      this.currentDisplayingElement.skip = true;
      this.skip = true;
      this.$forceUpdate();
    }
  }

  @Watch('messages')
  private displayNextMessage() {
    if (this.messages) {
      if (
        this.currentMessageIndex < this.messages.length &&
        this.renderNextMessage
      ) {
        if (!this.skip) {
          this.scrollToBottom();
        }

        if (this.deleteLastElementBeforeNextMessage) {
          if (this.currentDisplayingElement) {
            this.conversation.removeChild(this.currentDisplayingElement.$el);
          }
          this.deleteLastElementBeforeNextMessage = false;
        }

        this.displayMessage();
      } else if (
        this.messages[this.messages.length - 1] &&
        this.currentMessageIndex === this.messages.length &&
        !this.messages[this.messages.length - 1].userResponse
      ) {
        this.skip = false;

        if (this.radios && this.radios.length !== 0) {
          this.createRadioElement();
        }

        if (this.checkbox && this.checkbox.length !== 0) {
          this.createCheckboxElement();
        }

        if (this.rows && this.rows.length !== 0) {
          this.createRowElement();
        }

        if (this.selects && this.selects.length !== 0) {
          this.createSelectElement();
        }

        this.finish();
        this.scrollToBottom();
      }
    }
  }

  private async scrollToBottom() {
    await this.$nextTick();

    const isViewOnPageBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (isViewOnPageBottom) {
      return;
    }

    if (this.scrollAnimation) {
      this.scrollAnimation();
    }

    this.scrollAnimation = VueScrollTo.scrollTo('.scrollto-target', 1000, {
      container: 'body'
    });
  }

  private displayMessage() {
    this.currentMessage = this.messages[this.currentMessageIndex];

    if (this.currentMessage.type) {
      this.renderNextMessage = false;
      switch (this.currentMessage.type) {
        case 'string':
          return this.createStringElement();
        case 'warning':
          return this.createAlertElement(this.currentMessage.type);
        case 'cards':
          this.createCardElement();
          return this.createDisclaimerElement();
        case 'table':
          return this.createTableElement();
      }
    }

    if (!this.skip) {
      this.scrollToBottom();
    }
  }

  // --- VUE COMPONENT DYNAMIC CREATION

  private createStringElement() {
    this.currentDisplayingElement = new this.stringELEM({
      propsData: {
        text: this.currentMessage.userResponse
          ? Mustache.render(
              this.currentMessage.value as string,
              this.answerObject
            )
          : this.currentMessage.value,
        isLeftMessage: !this.currentMessage.userResponse,
        skip: this.skip,
        iconText: this.currentMessage.userResponse
          ? // @ts-ignore
            this.username[0]
          : this.trademarkInitial
      }
    });

    this.currentDisplayingElement.$on('finished', this.onMessageActionFinished);
    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
  }

  private createAlertElement(type: string) {
    this.currentDisplayingElement = new this.alertELEM({
      propsData: {
        text: this.currentMessage.value,
        type
      }
    });

    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
    this.onMessageActionFinished();
  }

  private createDisclaimerElement() {
    const cards = this.currentMessage
      .value as IConversationQuestionMessageCard[];
    const disclaimer = cards[cards.length - 1]?.disclaimer;
    if (!disclaimer) return;
    this.currentDisplayingElement = new this.disclaimerELEM({
      propsData: {
        text: disclaimer
      }
    });

    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
    this.onMessageActionFinished();
  }

  private createTableElement() {
    this.currentDisplayingElement = new this.tableELEM({
      propsData: {
        table: this.currentMessage.value
      }
    });

    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
    this.onMessageActionFinished();
  }

  private createCardElement() {
    this.currentDisplayingElement = new this.cardELEM({
      propsData: {
        cards: this.currentMessage.value
      }
    });

    this.currentDisplayingElement.$on('selected', this.selected);
    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
    this.onMessageActionFinished();
  }

  private createRadioElement() {
    this.currentDisplayingElement = new this.radioELEM({
      propsData: {
        radios: this.radios
      }
    });

    this.deleteLastElementBeforeNextMessage = true;

    this.currentDisplayingElement.$on('selected', (value: string) =>
      this.selected(value)
    );
    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
  }

  private createRowElement() {
    this.currentDisplayingElement = new this.rowELEM({
      propsData: {
        data: this.rows
      }
    });

    this.currentDisplayingElement.$on('selected', (value: string) =>
      this.selected(value)
    );
    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
    this.onMessageActionFinished();
  }

  private createCheckboxElement() {
    this.currentDisplayingElement = new this.checkboxELEM({
      propsData: {
        checkbox: this.checkbox
      }
    });

    this.deleteLastElementBeforeNextMessage = true;

    this.currentDisplayingElement.$on('selected', (value: string[]) =>
      this.selected(value)
    );
    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
  }

  private createSelectElement() {
    this.currentDisplayingElement = new this.selectELEM({
      propsData: {
        selectArray: this.selects,
        searchReturnEmptyElement: this.selectDefaultSearchEmptyElement,
        placeholderText: 'Selecione uma profissão'
      }
    });

    this.deleteLastElementBeforeNextMessage = true;

    this.currentDisplayingElement.$on('selected', (value: string[]) => {
      this.selected(value);
    });
    this.currentDisplayingElement.$mount();

    if (this.questionId !== '') {
      this.currentDisplayingElement.$el.classList.add(
        `messages_${this.questionId}`
      );
    }

    this.conversation.appendChild(this.currentDisplayingElement.$el);
  }

  private onMessageActionFinished() {
    this.currentMessageIndex++;
    this.renderNextMessage = true;
    this.displayNextMessage();
  }

  @Emit()
  private selected(value: string | string[]) {
    return value;
  }

  @Emit()
  private finish() {}
}
