
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NavBar, IconButton } from '@warrenbrasil/nebraska-web';
import { useDashStore } from '@/modules/common/store/DashModule';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import { StatementList } from './components/StatementList';
import { Balances } from './components/Balances';
import { FutureTransactionsView } from './components/FutureTransactionsView';
import { FutureTransactionsSection } from './components/FutureTransactionsSection';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { ShowMoneyToggleButton } from '@/modules/common/components/buttons/ShowMoneyToggleButton';

import { TransactionsSteps } from './types';
import * as trackEvents from './track-events';

@Component({
  components: {
    NavBar,
    IconButton,
    FadeTransition,
    StatementList,
    Balances,
    FutureTransactionsView,
    FutureTransactionsSection,
    ShowMoneyToggleButton
  }
})
export default class TransactionsView extends Vue {
  @Prop({ type: String, required: false })
  readonly transactionsStep?: TransactionsSteps;

  private readonly dashModule = useDashStore();

  readonly TransactionsSteps = TransactionsSteps;

  step: TransactionsSteps = TransactionsSteps.Statement;

  get iconName() {
    switch (this.step) {
      case TransactionsSteps.FutureTransactions:
        return 'EF0030';
      case TransactionsSteps.Statement:
      default:
        return 'EA0030';
    }
  }

  get isFutureTransactionsEnabled() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.FUTURE_TRANSACTIONS);
  }

  created() {
    if (this.transactionsStep) {
      this.step = this.transactionsStep;
    }
    trackEvents.trackTransactionsLoad();
  }

  handleClick() {
    switch (this.step) {
      case TransactionsSteps.FutureTransactions:
        this.closeFutureTransactions();
        break;
      case TransactionsSteps.Statement:
      default:
        this.goBack();
        break;
    }
  }

  toggleClick() {
    trackEvents.trackHideEquityClick();
  }

  closeFutureTransactions() {
    this.step = TransactionsSteps.Statement;
  }

  openFutureTransactions() {
    this.step = TransactionsSteps.FutureTransactions;
  }

  goBack() {
    this.$router.back();
  }
}
