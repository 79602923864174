
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Row, Tag, BaseText } from '@warrenbrasil/nebraska-web';
import { InstrumentName } from '@/modules/trade/components/InstrumentName';
import { MonetaryText } from '@/modules/trade/components/MonetaryText';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  CustomOperationSummary,
  CustomOperationStatus
} from '@/modules/trade/services/custom-operations';
import { CustomOperationsListOptions } from '../../types';
import { useDashStore } from '@/modules/common/store/DashModule';

@Component({
  components: {
    BaseText,
    InstrumentName,
    Row,
    Tag,
    MonetaryText
  }
})
export default class OperationRow extends Vue {
  private readonly dashModule = useDashStore();

  @Prop({ type: Object, required: true })
  readonly operation!: CustomOperationSummary;

  @Prop({ type: String, required: true })
  readonly selectedOption!: CustomOperationsListOptions;

  @Emit('on-click')
  emitOnClick() {
    return this.operation;
  }

  readonly NebraskaColors = NebraskaColors;

  private readonly showMoney: boolean = this.dashModule.showMoney;

  get instrumentType() {
    return this.operation.instrument.type?.value;
  }

  get isOperationStatusSettlement() {
    return this.operation.status.value === CustomOperationStatus.Settlement;
  }

  get isSelectOptionTotalFinancialResult() {
    return (
      this.selectedOption === CustomOperationsListOptions.TotalFinancialResult
    );
  }

  get isSelectOptionTotalQuantity() {
    return this.selectedOption === CustomOperationsListOptions.Quantity;
  }

  get ariaLabel() {
    return `Ver detalhes da operação ${this.operation.strategy.formatted}`;
  }
}
