
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  CustomOperationsList,
  CustomOperationsOption
} from '@/modules/trade/components/CustomOperationsList';

import {
  trackCustomOperationsSelectChange,
  trackCustomOperationsRetryClick,
  trackCustomOperationRowClick,
  trackCustomOperationsRequestError
} from '@/modules/trade/views/TradeView/trackers';

import {
  getCustomOperationsSummary,
  CustomOperationSummary
} from '@/modules/trade/services/custom-operations';
import { isBFFError } from '@/modules/common/helpers/bff';
import { logError } from '@/modules/trade/helpers/logs';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    CustomOperationsList
  }
})
export default class CustomOperationsSection extends Vue {
  loading = true;
  error = false;
  errorMessage = '';

  @tradePositionsModule.State('customOperations')
  readonly customOperations!: CustomOperationSummary[];

  @tradePositionsModule.Mutation('setCustomOperations')
  readonly setCustomOperations!: (
    customOperations: CustomOperationSummary[]
  ) => void;

  created() {
    this.fetchCustomOperations();
  }

  destroyed() {
    this.setCustomOperations([]);
  }

  get hasOperations() {
    return this.customOperations.length > 0;
  }

  get shouldRenderCustomOperationsList() {
    return this.hasOperations || this.loading || this.error;
  }

  async fetchCustomOperations() {
    try {
      this.loading = true;
      this.error = false;

      const { operations } = await getCustomOperationsSummary();

      this.setCustomOperations(operations);
    } catch (e) {
      logError(e, 'request_custom_operations_error');
      trackCustomOperationsRequestError();
      let errorMessage = 'Não foi possível carregar as operações.';
      if (isBFFError(e)) {
        errorMessage = e.error.message.formatted;
      }
      this.errorMessage = errorMessage;
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  handleOnSelect(option: CustomOperationsOption) {
    trackCustomOperationsSelectChange(option.label);
  }

  handleOnRetryList() {
    trackCustomOperationsRetryClick();
    this.fetchCustomOperations();
  }

  handleOnCustomOperationRowClick(operation: CustomOperationSummary) {
    trackCustomOperationRowClick(operation.instrument.symbol);
  }
}
