import { warrenAnalyticsObject } from '@/plugins/warren-analytics';
import { trackerContextProps } from '@/modules/common/services/analytics/tracker-context';

type Deposit = {
  type: string;
  amount: number;
  isFirstDeposit: boolean;
};

export function trackEvent({ type, amount, isFirstDeposit }: Deposit) {
  warrenAnalyticsObject.track({
    ...trackerContextProps(),
    event: 'Deposit Created',
    type,
    amount,
    isFirstDeposit
  });
}
