var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ContextColorProvider',{attrs:{"context":"brand"}},[_c('div',{staticClass:"wrn-m-b-2 wrn-m-b-md-4"},[_c('div',{staticClass:"wrn-container"},[_c('ConversationStringMessage',{attrs:{"text":"\n          Informe uma conta bancária para reconhecermos seus depósitos e enviarmos seus resgates. Use uma conta em que você é o 1º ou 2º titular.\n        ","is-left-message":true,"icon-text":_vm.getPartnerBrandName},on:{"finished":() => (_vm.showSummary = true)}})],1),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSummary),expression:"showSummary"}],ref:"bankDataForm",staticClass:"wrn-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"wrn-container wrn-row wrn-a-fade"},[_c('div',{staticClass:"wrn-text-left wrn-m-b-3 wrn-m-b-sm-2 wrn-c-sm-12 wrn-c-offset-lg-2 wrn-c-lg-8"},[_c('BankAccounts',{attrs:{"disable-add-bank":_vm.onlyBankData,"bank-accounts":_vm.bankAccounts,"errors":_vm.bankAccountsValidator.getAllErrors()},on:{"change":_vm.onChangeBankAccounts,"deleted":function($event){return _vm.deleteBankAccount($event)}}}),(!_vm.onlyBankData)?_c('FormField',{staticClass:"wrn-m-t-1",attrs:{"label-for":"totalInvested","label":_vm.getString('common_field_financial_investments'),"input-l-g":true}},[_c('Money',_vm._b({attrs:{"id":"totalInvested","maxlength":"17"},model:{value:(_vm.investmentProfile.totalInvested),callback:function ($$v) {_vm.$set(_vm.investmentProfile, "totalInvested", $$v)},expression:"investmentProfile.totalInvested"}},'Money',{
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                masked: false
              },false))],1):_vm._e(),(!_vm.onlyBankData)?_c('BaseText',{attrs:{"as":"span","size":"sm"}},[_vm._v(" "+_vm._s(_vm.getString('common_field_financial_investments_tooltip'))+" "+_vm._s(_vm.getString('register_step3_warren_financial_data_description'))+" ( "),_c('a',{attrs:{"target":"_blank","href":_vm.getString('register_step3_warren_financial_data_link_raw')}},[_vm._v(" "+_vm._s(_vm.getString( 'register_step3_warren_financial_data_description_link' ))+" ")]),_vm._v(" ) ")]):_vm._e(),(!_vm.onlyBankData)?_c('FormField',{staticClass:"wrn-m-t-1",class:{
              'form-error': _vm.getValidationError('investmentProfile.deposit')
            },attrs:{"label-for":"deposit","label":_vm.getString('common_field_deposit_label'),"error":_vm.getValidationError('investmentProfile.deposit'),"input-l-g":true}},[_c('Money',_vm._b({attrs:{"id":"deposit","maxlength":"17"},model:{value:(_vm.investmentProfile.deposit),callback:function ($$v) {_vm.$set(_vm.investmentProfile, "deposit", $$v)},expression:"investmentProfile.deposit"}},'Money',{
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                masked: false
              },false))],1):_vm._e(),(!_vm.onlyBankData)?_c('BaseText',{attrs:{"as":"span","size":"sm"}},[_vm._v(" "+_vm._s(_vm.getString('common_field_deposit_tooltip'))+" "+_vm._s(_vm.getString('register_step3_warren_financial_data_description'))+" ( "),_c('a',{attrs:{"target":"_blank","href":_vm.getString('register_step3_warren_financial_data_link_raw')}},[_vm._v(" "+_vm._s(_vm.getString( 'register_step3_warren_financial_data_description_link' ))+" ")]),_vm._v(" ) ")]):_vm._e()],1)]),_c('div',{staticClass:"wrn-inputbar wrn-inputbar-fixed button"},[_c('ButtonPrimary',{attrs:{"size":"large","type":"submit","loading":_vm.isLoading,"label":_vm.getString('common_field_fast_register_save_button')}})],1)]),_c('Notification',{attrs:{"group":"wrn-register-notifications"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }