
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @deprecated - use Nebraska FormField instead
 */
export default class FormField extends Vue {
  @Prop({ type: String, required: false })
  private labelFor?: string;

  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, required: false })
  private labelColor?: string;

  @Prop({ type: String, default: '' })
  private error!: string;

  @Prop({ type: Boolean, default: true })
  private inputLG!: boolean;

  @Prop({ type: String, default: '' })
  private errorColor!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  private created() {
    console.warn(
      'Using deprecated FormField, please use Nebraska FormField instead.'
    );
  }
}
