
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { Row } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Row
  }
})
export default class ConversationRowsMessage extends Vue {
  @Prop({ type: Array, required: true })
  public data?: any[];

  @Prop({ type: Function, default: () => '' })
  public onRowAction?: (value: string) => '';

  @Emit('selected')
  private selected(value: string) {
    if (this.onRowAction) this.onRowAction(value);
    return value;
  }
}
