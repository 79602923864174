
import { Vue, Component } from 'vue-property-decorator';
import {
  Heading,
  SkeletonLoader,
  ButtonSecondary,
  Row
} from '@warrenbrasil/nebraska-web';

import {
  getContractDetails,
  IContractDetails
} from '@/modules/trade/services/contracts-btc';
import { InstrumentBtc } from './components/InstrumentBtc';

@Component({
  components: {
    Heading,
    InstrumentBtc,
    SkeletonLoader,
    ButtonSecondary,
    Row
  }
})
export default class InstrumentAllocation extends Vue {
  public contracts!: IContractDetails;
  public isLoading = true;
  public isError = false;

  fetchCustodyBtc() {
    this.isLoading = true;
    this.isError = false;
    getContractDetails()
      .then((contractsData: IContractDetails) => {
        this.contracts = contractsData;
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  created() {
    this.fetchCustodyBtc();
  }
}
