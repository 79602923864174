import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CopyMixin extends Vue {
  public COPY_CLASS_NAME = 'copy-to-clipboard';
  public copyToClipboard(value: string) {
    try {
      let copyInput: any = document.querySelector(`.${this.COPY_CLASS_NAME}`);

      if (!copyInput) {
        copyInput = document.createElement('input');
        copyInput.style.position = 'fixed';
        copyInput.style.top = '-100%';
        copyInput.style.left = 0;
        copyInput.style.padding = 0;
        copyInput.style.margin = 0;
        copyInput.style.width = '2em';
        copyInput.style.height = '2em';
        copyInput.style.border = 'none';
        copyInput.style.outline = 'none';
        copyInput.style.boxShadow = 'none';
        copyInput.style.background = 'transparent';
        copyInput.style.color = 'transparent';
        copyInput.className = this.COPY_CLASS_NAME;
        copyInput.setAttribute('type', 'text');
        document.documentElement.appendChild(copyInput);
      }

      copyInput.value = value;
      document.execCommand('copy', false, String(copyInput.select()));
    } catch (err) {
      throw new Error(`CopyMixin: unable to copy correctly: ${err}`);
    }
  }
}
