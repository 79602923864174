import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { Withdraw, CreateWithdraw } from './withdraws-types';

export default class WithdrawsAPI extends BaseHttpClient {
  public async getWithdrawal(id: string): Promise<Withdraw> {
    const { withdraw } = await this.get<{ withdraw: Withdraw }>(
      `/api/v2/withdraws/single/${id}`
    );
    return withdraw;
  }

  public cancelWithdrawal(id: string): Promise<any> {
    return this.put<any>(`/api/v2/withdraws/single/${id}/cancel`);
  }

  public createWithdrawal(data: CreateWithdraw) {
    return this.post<{ withdraw: Withdraw }>('api/v2/withdraws/create', data);
  }
}
