import { object, number } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';
import { getString } from '@/modules/common/helpers/resources';

const schema = object().shape({
  investmentProfile: object().shape({
    deposit: number().required(getString('common_field_deposit_error_required'))
  })
});

export default new YupValidation(schema);
