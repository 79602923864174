var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ContextColorProvider',[(_vm.isError)?_c('div',{staticClass:"transaction-view__error-container"},[_c('ActionableHelperView',{staticClass:"transaction-view__error-container__error",attrs:{"on-retry":_vm.reload}})],1):_c('div',[_c('NavBar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [(!_vm.displayBankAccount)?_c('RoundedIcon',{attrs:{"role":"button","icon-name":"ic_arrow_left","main-color":"transparent","text-color":_vm.NebraskaColors.elementPrimary,"hover-color":_vm.NebraskaColors.backgroundHover,"aria-label":"Voltar","has-hover":""},on:{"click":_vm.onBackClick,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onBackClick.apply(null, arguments)}}}):_vm._e()]},proxy:true},{key:"center",fn:function(){return [_c('span',{attrs:{"data-testid":"new-transaction-in-conversation"}},[_vm._v(" Transferência ")])]},proxy:true}])}),_c('BankData',{class:{
        'bankAccount-display': _vm.displayBankAccount,
        'hide-on-bankAccount': !_vm.displayBankAccount
      },attrs:{"only-bank-data":true,"user":_vm.customer},on:{"registered":function($event){return _vm.registeredBankAccount($event)}}}),_c('Conversation',{ref:"conversation",class:{
        'hide-on-bankAccount': _vm.displayBankAccount,
        'display-on-bankAccount': !_vm.displayBankAccount
      },attrs:{"conversation-answer-data":_vm.answerData,"conversation-question-data":_vm.questionData,"user-slot-input":_vm.slotAnswer,"user-messages":_vm.handleUserResponses,"questions-with-slot-inputs":_vm.conversationSlotQuestions,"trademark-initial":_vm.trademarkInitial,"show-deposit-button":_vm.showDepositButton},on:{"responded":_vm.handleMessageData,"responding":loading => (_vm.isRespondingQuestion = loading)}},[(_vm.isFinishDepositConversation)?_c('DepositTransaction',{attrs:{"transaction":_vm.questionData.transaction},on:{"error":() => (_vm.isError = true),"click-finished":_vm.onBackTo}}):(_vm.isFinishInvoiceConversation)?_c('DepositInvoiceTransaction',{attrs:{"transaction":_vm.questionData.transaction},on:{"error":() => (_vm.isError = true),"click-finished":_vm.invoiceFinish}}):_vm._e(),(
          _vm.isFinishWithdrawConversation && !_vm.isWithdrawalConversationEnabled
        )?_c('WithdrawTransaction',{attrs:{"withdraw":_vm.questionData.transaction},on:{"error":() => (_vm.isError = true),"click-finished":_vm.withdrawFinish}}):_vm._e(),(_vm.isFinishWithdrawConversation && _vm.isWithdrawalConversationEnabled)?_c('ConfirmationWithdrawTransaction',{attrs:{"withdraw":_vm.questionData.transaction},on:{"error":() => (_vm.isError = true),"click-finished":_vm.withdrawFinish}}):_vm._e(),(_vm.isFinishInternalMovementConversation)?_c('InternalMovementTransaction',{attrs:{"transaction":_vm.questionData.transaction},on:{"error":() => (_vm.isError = true),"click-finished":_vm.onBackTo}}):_vm._e(),(_vm.isRespondingQuestion)?_c('div',[(
            _vm.questionData.id === 'question_portfolio' ||
            _vm.questionData.id === 'transfer_to_portfolio'
          )?_c('div',{staticClass:"transaction-view__card-container"},_vm._l((_vm.parsedPortfolios),function(portfolio,index){return _c('div',{key:index,staticClass:"transaction-view__card-wrapper"},[_c('BaseCard',{staticClass:"transaction-view__card",attrs:{"background":portfolio.label.image},on:{"click":function($event){return _vm.portfolioSlotInputResponse(portfolio, _vm.questionData.id)}}},[_c('Heading',{staticClass:"transaction-view__card-title",attrs:{"slot":"center","level":3},slot:"center"},[_vm._v(" "+_vm._s(portfolio.label.title)+" ")])],1)],1)}),0):_vm._e()]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }