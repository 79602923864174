import store from '@/store';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import {
  ConversationAPI,
  IConversationQuestion
} from '@/modules/conversation/services/conversation';

import { IConversationDataTransaction } from '@/types/models/Transaction';
import { parseQuestionData } from './helper';

export interface ITransactionState {
  conversationAnswer: IConversationDataTransaction;
  conversationQuestion: IConversationQuestion;
}

const conversationAPI = new ConversationAPI();

@Module({
  dynamic: true,
  namespaced: true,
  name: 'TransactionState',
  store
})
export class TransactionState extends VuexModule {
  public conversationAnswer: IConversationDataTransaction = {
    context: 'transactions',
    id: 'initial',
    portfolio: '',
    answers: {}
  };

  public conversationQuestion: Partial<IConversationQuestion> = {
    id: '',
    buttons: [],
    inputs: [],
    radios: [],
    checkbox: [],
    messages: [],
    responses: [],
    clearMessages: true
  };

  public stateHistory: ITransactionState[] = [];

  @Mutation
  public clearStateHistory() {
    this.stateHistory = [];
  }

  @Mutation
  public setConversationData(value: IConversationDataTransaction) {
    this.conversationAnswer = value;
  }

  @Mutation
  public setConversationQuestion(value: IConversationQuestion) {
    this.conversationQuestion = value;
  }

  @Action({ rawError: true })
  public async fetchNextQuestion(
    conversationData: IConversationDataTransaction
  ): Promise<IConversationQuestion> {
    const response = await conversationAPI.getTransactionConversationQuestion(
      conversationData
    );
    const parsedResponse = parseQuestionData({
      ...response,
      clearMessages: true
    });

    this.context.commit('setConversationData', conversationData);
    this.context.commit('setConversationQuestion', parsedResponse);

    if (conversationData.id !== '' && parsedResponse.id !== '') {
      this.registerStateHistory({
        conversationAnswer: conversationData,
        conversationQuestion: parsedResponse
      });
    }

    return parsedResponse;
  }

  @Action({ rawError: true })
  public async rollbackQuestion(): Promise<ITransactionState> {
    if (this.stateHistory.length > 1) {
      this.stateHistory.pop();
    } else {
      return this.stateHistory[0];
    }

    const answer =
      this.stateHistory[this.stateHistory.length - 1].conversationAnswer;
    const question =
      this.stateHistory[this.stateHistory.length - 1].conversationQuestion;

    this.context.commit('setConversationData', answer);
    this.context.commit('setConversationQuestion', question);

    return this.stateHistory[this.stateHistory.length - 1];
  }

  @Action({ rawError: true })
  public async registerStateHistory(state: ITransactionState) {
    this.stateHistory.push({
      conversationAnswer: state.conversationAnswer,
      conversationQuestion: state.conversationQuestion
    });
  }
}

export const TransactionModule = getModule(TransactionState);
