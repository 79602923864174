
import { Component } from 'vue-property-decorator';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { TheMask } from 'vue-the-mask';
import { mixins } from 'vue-class-component';
import { ConversationInputBase } from '../ConversationInputBase';

@Component({
  components: { TheMask, ButtonPrimary }
})
export default class ConversationMaskInput extends mixins(
  ConversationInputBase
) {
  private mask = '';
  private placeholder = '';

  mounted() {
    switch (this.input.mask) {
      case 'date':
        if (this.inputValue !== '')
          this.inputValue = new Date(this.inputValue).toLocaleDateString();
        this.mask = '##/##/####';
        this.placeholder = 'DD/MM/AAAA';
    }
  }
}
