
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { NavBar } from '@warrenbrasil/nebraska-web';
import { CloseButton } from '@/modules/common/components/buttons';
import { BackButton } from '@/modules/common/components/buttons/BackButton';

import { CustomOperationSteps } from '../../types';

@Component({
  components: {
    CloseButton,
    BackButton,
    NavBar
  }
})
export default class CustomOperationNavBar extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Boolean, default: false })
  readonly showBackButton!: boolean;

  readonly CustomOperationSteps = CustomOperationSteps;

  @Emit('change-step')
  emitChangeStep(_step: CustomOperationSteps) {}

  @Emit('close')
  emitClose() {}
}
