
import { Component, Vue, Prop } from 'vue-property-decorator';

import { TableDisplayColumn, ColumnGetter, TableDisplaySizes } from './types';

@Component({})
export default class MonitoringBarriersTable extends Vue {
  @Prop({ type: Array, required: true })
  readonly data!: any[];

  @Prop({ type: Array, required: true })
  readonly columns!: TableDisplayColumn[];

  @Prop({
    type: String,
    default: TableDisplaySizes.MEDIUM,
    validator: (value: TableDisplaySizes) =>
      Object.values(TableDisplaySizes).includes(value)
  })
  readonly size!: TableDisplaySizes;

  get computedCellClass() {
    return {
      'table-display__cell': true,
      'table-display__cell--small': this.size === TableDisplaySizes.SMALL,
      'table-display__cell--medium': this.size === TableDisplaySizes.MEDIUM,
      'table-display__cell--large': this.size === TableDisplaySizes.LARGE
    };
  }

  getCellValue(getter: ColumnGetter, row: any) {
    if (typeof getter === 'function') {
      return getter(row);
    }
    return row[getter];
  }

  getCellAlignment(column: TableDisplayColumn) {
    return column.align || 'left';
  }
}
