
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Row, BaseText } from '@warrenbrasil/nebraska-web';

import { IContractDetails } from '@/modules/trade/services/contracts-btc';
import { InstrumentBtcModal } from './components/InstrumentBtcModal';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Row,
    InstrumentBtcModal,
    BaseText
  }
})
export default class InstrumentBtc extends Vue {
  @Prop({ type: Object, required: true })
  readonly contractsData!: IContractDetails;

  public detailModal = false;
  readonly NebraskaColors = NebraskaColors;

  handleDetailModal(option: boolean) {
    this.detailModal = option;
  }
}
