
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IConversationQuestionRadio } from '@/modules/conversation/services/conversation';
import { Radio } from '@/modules/common/components/__deprecated__/Radio';
import { Columns } from '@/modules/common/components/__deprecated__/Columns';

@Component({
  components: { Radio, Columns }
})
export default class ConversationRadioMessage extends Vue {
  @Prop(Array)
  public radios!: IConversationQuestionRadio[];

  private selectedRadio(radio: IConversationQuestionRadio) {
    this.selected(radio.value);
  }

  private get shouldUseGridDisplay() {
    return this.radios.length > 5;
  }

  @Emit()
  private selected(value: string) {
    return value;
  }
}
