
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { BaseText, Heading, Select } from '@warrenbrasil/nebraska-web';
import { HandleSection } from '@/modules/trade/components/HandleSection';
import { OperationRow } from './components/OperationRow';
import { CustomOperationModal } from '@/modules/trade/components/CustomOperationModal';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { CustomOperationSummary } from '@/modules/trade/services/custom-operations';
import { CustomOperationsListOptions, CustomOperationsOption } from './types';

@Component({
  components: {
    BaseText,
    CustomOperationModal,
    HandleSection,
    OperationRow,
    Heading,
    Select
  }
})
export default class CustomOperationsList extends Vue {
  @Prop({ type: Array, required: true })
  readonly operations!: CustomOperationSummary[];

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  @Prop({ type: String, default: 'Não foi possível carregar as operações.' })
  readonly errorMessage!: string;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  currentSelectedOperationId = '';
  isOperationModalOpened = false;

  @Emit('on-retry-list')
  emitOnRetry() {}

  @Emit('on-select')
  emitOnSelect() {
    return this.selectedOption;
  }

  @Emit('on-custom-operation-row-click')
  emitOnCustomOperationRowClick(_operation: CustomOperationSummary) {}

  selectedOption: CustomOperationsOption = {
    value: CustomOperationsListOptions.TotalEarnings,
    label: 'Resultado'
  };

  options = [
    {
      value: CustomOperationsListOptions.TotalEarnings,
      label: 'Resultado'
    },
    {
      value: CustomOperationsListOptions.Quantity,
      label: 'Quantidade'
    },
    {
      value: CustomOperationsListOptions.TotalFinancialResult,
      label: 'Valor total'
    }
  ];

  readonly NebraskaColors = NebraskaColors;

  get hasOperations() {
    return this.operations.length > 0;
  }

  onSelect(option: CustomOperationsOption) {
    this.selectedOption = option;
    this.emitOnSelect();
  }

  handleCustomOperationRowClick(operation: CustomOperationSummary) {
    this.emitOnCustomOperationRowClick(operation);
    this.handleOpenCustomOperationModal(operation);
  }

  handleOpenCustomOperationModal(operation: CustomOperationSummary) {
    this.isOperationModalOpened = true;
    this.currentSelectedOperationId = operation.id;
  }

  handleCloseCustomOperationModal() {
    this.isOperationModalOpened = false;
    this.currentSelectedOperationId = '';
  }
}
