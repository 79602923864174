import { NavigationGuard } from 'vue-router';

import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

export const beforeEnterProfit: NavigationGuard = (_to, _from, next) => {
  const isFeatureFlagEnabled = FeatureFlagsModule.isEnabled(
    FeatureFlags.ENABLE_PROFIT_VIEW
  );

  if (!isFeatureFlagEnabled) {
    return next({ name: 'trade' });
  }

  next();
};
