import { render, staticRenderFns } from "./InstrumentAllocation.vue?vue&type=template&id=06a691a1&scoped=true&"
import script from "./InstrumentAllocation.vue?vue&type=script&lang=ts&"
export * from "./InstrumentAllocation.vue?vue&type=script&lang=ts&"
import style0 from "./InstrumentAllocation.vue?vue&type=style&index=0&id=06a691a1&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a691a1",
  null
  
)

export default component.exports