
import { Component, Prop, Vue } from 'vue-property-decorator';

const mapAlert = new Map<string, string>([
  ['warning', 'alert-warning'],
  ['error', 'alert-error']
]);

@Component
export default class ConversationAlertMessage extends Vue {
  @Prop({ type: String, default: '' })
  public text!: string;

  @Prop({ type: String, default: 'warning' })
  public type!: string;

  private get getComputedStyle() {
    return mapAlert.get(this.type);
  }
}
