
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { TheMask } from 'vue-the-mask';
import { Money } from 'v-money';
import { FormField } from '@/modules/common/components/__deprecated__/FormField';
import { BankAccounts } from '../BankAccounts';
import { customerAPI } from '@/modules/common/services/http/customer';
import { getString, getEnum } from '@/modules/common/helpers/resources';
import scrollToElement from 'scroll-to-element';
import bankDataValidation from '../../bank-data-validation';
import getBankAccountsValidator from '../../bank-accounts-validation';
import { ICustomer } from '@/types/models/Customer';
import { IInvestmentProfile } from '@/types/models/Customer/InvestmentProfile';
import { IBankAccount } from '@/types/models';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { Notification } from '@/modules/common/components/Notification';
import { bankAccountDelete } from '@/modules/transaction/services/bank-account';
import { getBrandInitial } from '@/modules/common/services/partner';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ButtonPrimary,
  BaseText,
  ContextColorProvider
} from '@warrenbrasil/nebraska-web';
import { ConversationStringMessage } from '@/modules/conversation/components/messages';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';

type BankAccount = Partial<IBankAccount>;

@Component({
  components: {
    ConversationStringMessage,
    FormField,
    TheMask,
    ButtonPrimary,
    BankAccounts,
    BaseText,
    Money,
    Notification,
    ContextColorProvider
  }
})
export default class BankData extends Vue {
  @Prop({
    type: Function,
    default: () => {}
  })
  private onRegisterFinished!: (data: any) => void;

  @Prop({
    type: Object,
    default: {
      investmentProfile: {},
      bankAccounts: []
    }
  })
  private user!: ICustomer;

  @Prop({ type: Boolean, default: false })
  private onlyBankData!: boolean;

  // @ts-ignore
  private investmentProfile: IInvestmentProfile = {};
  private bankAccounts: BankAccount[] = [];
  private errors: YupValidationErrors = {};
  private singleErrors: YupValidationErrors = {};
  private bankAccountsValidator = getBankAccountsValidator();
  private isLoading = false;
  private showSummary = false;
  private getString = getString;
  private getEnum = getEnum;

  private deletedBankAccountsArray: IBankAccount[] = [];

  private NebraskaColors = NebraskaColors;

  private async mounted() {
    // @ts-ignore
    this.investmentProfile = this.user.investmentProfile;
    // @ts-ignore
    this.bankAccounts = this.user.bankAccounts;
  }

  private isWhitelabel() {
    return !!localStorage.getItem(StorageKeys.WhitelabelTheme);
  }

  private get primary() {
    const displayBlackIcon = this.isWhitelabel() || this.onlyBankData;
    return displayBlackIcon
      ? NebraskaColors.elementPrimary
      : NebraskaColors.brandPrimary;
  }

  private get overPrimary() {
    const displayBlackIcon = this.isWhitelabel() || this.onlyBankData;
    return displayBlackIcon
      ? NebraskaColors.textNegative
      : NebraskaColors.brandOverPrimary;
  }

  private getValidationError(path: string): string {
    const err = this.errors[path];
    if (err) {
      return err[0];
    }
    const singleErr = this.singleErrors[path];
    if (singleErr) {
      return singleErr[0];
    }

    return '';
  }

  private getPOSTData() {
    // @ts-ignore
    const depositData: number = parseInt(this.investmentProfile.deposit, 10);
    const deposit: number = Number.isInteger(depositData) ? depositData : 0;

    const investedData: number = parseInt(
      // @ts-ignore
      this.investmentProfile.totalInvested,
      10
    );
    const totalInvested: number = Number.isInteger(investedData)
      ? investedData
      : 0;

    return {
      user: {
        bankAccounts: this.bankAccounts,
        investmentProfile: {
          // @ts-ignore
          totalInvested,
          deposit
        }
      }
    };
  }

  private focusOnErrorElement() {
    const form = this.$refs.bankDataForm;
    // @ts-ignore
    const errorElement = form.querySelector('.wrn-color-error') || {};
    // @ts-ignore
    const errorField = (errorElement.parentElement || {}).parentElement;
    scrollToElement(errorField, {
      offset: 0,
      duration: 300
    });
  }

  private async blurErrorCheck(key: string) {
    try {
      await bankDataValidation.validate(this.getPOSTData());
      this.errors[key] = [];
      this.singleErrors[key] = [];
    } catch (errors) {
      this.errors[key] = errors[key];
      this.singleErrors[key] = errors[key];
      this.$forceUpdate();
    }
  }

  private onChangeBankAccounts(bankAccounts: BankAccount[]) {
    this.bankAccounts = bankAccounts;
  }

  private async deleteBankAccount(bank: IBankAccount) {
    this.deletedBankAccountsArray.push(bank);
  }

  private get getPartnerBrandName() {
    return getBrandInitial(this.user.partnerInfos);
  }

  private async onSubmit() {
    if (this.isLoading) return null;

    this.deletedBankAccountsArray.forEach(bank => {
      if (bank._id) bankAccountDelete(bank._id);
    });

    if (this.onlyBankData) {
      this.registerBankData();
    } else {
      this.registerBankAndUserData();
    }
  }

  private async registerBankData() {
    const data = this.getPOSTData();
    this.isLoading = true;

    try {
      await this.bankAccountsValidator.validate(data.user.bankAccounts);
      this.errors = {};
    } catch (errors) {
      this.isLoading = false;
      this.errors = errors;
      this.$nextTick(() => {
        this.focusOnErrorElement();
      });

      return null;
    }

    try {
      const bankAccount = await customerAPI.registerBankAccount({
        bankAccount: data.user.bankAccounts[0]
      });

      this.isLoading = false;
      this.registered(bankAccount);
    } catch (err) {
      this.$notify({
        group: 'wrn-register-notifications',
        text:
          err.description ||
          'Erro ao registrar, verifique os campos e tente novamente',
        type: NotificationTypes.error
      });
      this.isLoading = false;
    }
  }

  private async registerBankAndUserData() {
    const data = this.getPOSTData();
    this.isLoading = true;

    try {
      await bankDataValidation.validate(data.user);
      await this.bankAccountsValidator.validate(data.user.bankAccounts);
      this.errors = {};
    } catch (errors) {
      this.errors = errors;
      this.$nextTick(() => {
        this.focusOnErrorElement();
      });

      return null;
    }

    try {
      const response = await customerAPI.register(data);
      this.isLoading = false;
      this.onRegisterFinished(response);
    } catch (err) {
      this.$notify({
        group: 'wrn-register-notifications',
        text:
          getString(err.message) ||
          'Erro ao registrar, verifique os campos e tente novamente',
        type: NotificationTypes.error
      });
      this.isLoading = false;
    }
  }

  @Watch('investmentProfile', { immediate: true, deep: true })
  private currencyAbsoluteValue() {
    if (
      this.investmentProfile.totalInvested &&
      this.investmentProfile.totalInvested < 0
    ) {
      this.investmentProfile.totalInvested = Math.abs(
        this.investmentProfile.totalInvested
      );
    }
    if (this.investmentProfile.deposit && this.investmentProfile.deposit < 0) {
      this.investmentProfile.deposit = Math.abs(this.investmentProfile.deposit);
    }
  }

  @Emit('registered')
  private registered(bankAccount: IBankAccount) {
    return bankAccount;
  }
}
