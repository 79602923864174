import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { MenuItem } from './components/NavbarMenu';
import { WarrenModules } from '@/types/navigation';

type MenuItemWithFeature = MenuItem & { feature?: FeatureFlags };

export const menuItems: MenuItemWithFeature[] = [
  {
    to: WarrenModules.Home,
    label: 'Home',
    icons: {
      active: {
        name: 'ic_home_active',
        color: NebraskaColors.themePrimary
      },
      inactive: {
        name: 'ic_home_inactive'
      }
    }
  },
  {
    to: WarrenModules.Wealth,
    label: 'Carteiras',
    feature: FeatureFlags.WEALTH_3_0,
    icons: {
      active: {
        name: 'ic_wallet_active',
        color: NebraskaColors.themePrimary
      },
      inactive: {
        name: 'ic_wallet_inactive'
      }
    }
  },
  {
    to: WarrenModules.Trade,
    label: 'Bolsa',
    feature: FeatureFlags.TRADE_3_0,
    icons: {
      active: {
        name: 'ic_trade_active',
        color: NebraskaColors.themePrimary
      },
      inactive: {
        name: 'ic_trade_inactive'
      }
    }
  }
];

export function getMenuItems() {
  return menuItems.filter(
    item => !item.feature || FeatureFlagsModule.isEnabled(item.feature)
  );
}
