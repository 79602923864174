var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseText',{class:{
    'badge--status-error': _vm.error | _vm.negative,
    'badge--status-success': _vm.success | _vm.positive,
    'wrn-custom-theme-badge': _vm.customTheme,
    'wrn-color-white': _vm.black | _vm.blue,
    'wrn-bg-black': _vm.black,
    'wrn-bg-blue': _vm.blue
  },style:({
    backgroundColor: _vm.customBackgroundColor,
    color: _vm.customColor,
    fontSize: _vm.fontSize
  }),attrs:{"as":"span","size":"sm"}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }