import { GtmTracker } from '@/modules/common/services/analytics';

const trackAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'custom_operations_information',
  digital_experience: 'investor',
  experience_section: 'broker'
});

export function trackCustomOperationModalRetryButtonClick() {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations_information',
    event_name: 'try_again'
  });
}

export function trackCustomOperationModalLoad() {
  trackAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackCustomOperationTooltipClick(label: string) {
  trackAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations_information',
    event_name: 'tooltip_performance',
    event_target: label
  });
}
