import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { IConversationQuestion, conversationAnswerDataTypes } from './types';

export class ConversationAPI extends BaseHttpClient {
  public async getConversationQuestion(
    data: conversationAnswerDataTypes
  ): Promise<IConversationQuestion> {
    const customerId = localStorage.getItem('warren.customerId');
    const url = `/api/v2/conversation/message${
      customerId ? `/${customerId}` : ''
    }`;

    return await this.post<IConversationQuestion>(url, data);
  }

  public async getTransactionConversationQuestion(
    data: conversationAnswerDataTypes
  ): Promise<IConversationQuestion> {
    return await this.post<IConversationQuestion>(
      '/api/v2/conversation/message',
      data
    );
  }
}
