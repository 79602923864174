
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Button, Heading, Row, BaseIcon } from '@warrenbrasil/nebraska-web';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import {
  TransfersAPI,
  CreateTransfer
} from '@/modules/transaction/services/transfer';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { GtmTracker } from '@/modules/common/services/analytics';

@Component({
  components: {
    Heading,
    Row,
    Button,
    BaseIcon
  },
  filters: { money }
})
export default class InternalMovementTransaction extends Vue {
  @Prop({ type: Object, required: true })
  private transaction!: CreateTransfer;

  private isLoading = false;
  private NebraskaColors = NebraskaColors;
  private CustomerModule = CustomerModule;

  private async handleCreateInternalMovement() {
    try {
      const transfersAPI = new TransfersAPI();
      this.isLoading = true;
      await transfersAPI.createTransfer(this.transaction);
      this.track();
      this.$emit('click-finished');
    } catch (error) {
      this.isLoading = false;
      this.$emit('error');
    }
  }

  private track() {
    GtmTracker.trackEvent({
      action: 'Transfer Created',
      label: '',
      category: '',
      value: this.transaction.amount,
      fromPortfolio: this.transaction.fromPortfolio,
      toPortfolios: this.transaction.toPortfolios.join()
    });
  }
}
