
/* eslint-disable prefer-regex-literals */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isFunction } from 'lodash-es';

interface IHTMLCommand {
  command: string;
  index: number;
}

interface MessageDelay {
  MESSAGE: string;
  DELAY: number;
}

@Component
export default class TypeWriter extends Vue {
  @Prop({ type: String, required: true }) private message!: string;
  @Prop({ type: Number, default: 10 }) private delay!: number;
  @Prop({ type: Boolean, default: true }) private start?: boolean;
  @Prop(Boolean) private skip?: boolean;
  @Prop(Function) private onStart?: () => void;
  @Prop(Function) private onFinish?: () => void;
  @Prop(Function) private afterChar?: () => void;
  @Prop(Function) private beforeChar?: () => void;
  private timers: NodeJS.Timer[] = [];

  private editableMessage = '';

  public mounted() {
    this.initialize();
  }

  public destroyed() {
    this.timers.forEach(clearTimeout);
  }

  @Watch('message')
  @Watch('start')
  private initialize() {
    this.editableMessage = this.message;

    if (this.start) {
      if (isFunction(this.onStart)) {
        this.onStart();
      }

      const message = this.handleMessage(this.editableMessage);
      this.typewrite(this.$el as HTMLElement, message);
    }
  }

  private handleMessage(message: string) {
    return String(message)
      .replace(/<a /g, '<a target="_blank" ')
      .replace(new RegExp(/\\n/g, 'g'), '<br />')
      .replace(new RegExp(/\\s/g, 'g'), ' ')
      .trim();
  }

  private getNextChar(message: string, currentIndex = 0): string {
    return message.substring(currentIndex, currentIndex + 1);
  }

  private getNextHTMLElementIndex(
    message: string,
    currentIndex = 0
  ): IHTMLCommand {
    let nextChar = this.getNextChar(message, currentIndex++);
    let HTMLCommand = '';

    while (nextChar && nextChar !== '>' && currentIndex <= message.length) {
      nextChar = this.getNextChar(message, currentIndex++);

      if (nextChar !== '>') {
        HTMLCommand += nextChar;
      }
    }

    return {
      command: HTMLCommand,
      index: currentIndex
    };
  }

  private getNextDelayAndRemove(message: string, index = 0): MessageDelay {
    const initialIndex = index;
    let nextChar = this.getNextChar(message, index++);
    let delayValue = '';

    while (nextChar && !isNaN(Number(nextChar))) {
      delayValue += nextChar;

      nextChar = this.getNextChar(message, index++);
    }

    const MESSAGE = message.replace(
      /[ˆ^][\d]+[\s]*/,
      message[initialIndex - 2] === ' ' ? '' : ' '
    );
    const DELAY = parseInt(delayValue, undefined);

    return {
      MESSAGE,
      DELAY
    };
  }

  private executeHTMLCommand(
    command: string,
    indexAfterCommand: number,
    element: HTMLElement
  ) {
    switch (command) {
      case 'erase':
        if (element.innerHTML.length === 0) {
          return this.typewrite(
            element,
            this.editableMessage.substring(
              indexAfterCommand,
              this.editableMessage.length
            ),
            0,
            this.delay
          );
        }

        if (this.skip) {
          element.innerHTML = '';

          return this.typewrite(
            element,
            this.editableMessage.substring(
              indexAfterCommand,
              this.editableMessage.length
            ),
            0,
            this.delay
          );
        } else {
          const timerToType: NodeJS.Timer = setTimeout(() => {
            element.innerHTML = element.innerHTML.slice(0, -1);

            return this.executeHTMLCommand('erase', indexAfterCommand, element);
          }, this.delay);

          this.timers.push(timerToType);
        }
        break;
    }
  }

  private typewrite(
    element: HTMLElement,
    message: string,
    charIndex = 0,
    delay: number = this.delay
  ): void {
    if (charIndex <= message.length) {
      const nextChar = this.getNextChar(message, charIndex);
      switch (nextChar) {
        case 'ˆ': // DELAY
        case '^': {
          // DELAY
          const beforeReplaceIndex = charIndex;
          const { MESSAGE, DELAY } = this.getNextDelayAndRemove(
            message,
            ++charIndex
          );

          this.editableMessage = MESSAGE;

          return this.typewrite(
            element,
            this.editableMessage,
            beforeReplaceIndex - 1,
            DELAY
          );
        }
        case '<': {
          // HTML ELEMENT
          const htmlContent = this.getNextHTMLElementIndex(message, charIndex);

          if (htmlContent.command === 'erase') {
            return this.executeHTMLCommand(
              htmlContent.command,
              htmlContent.index,
              element
            );
          }

          return this.typewrite(element, message, htmlContent.index, delay);
        }
        default: {
          if (this.skip) {
            element.innerHTML = message.substring(0, charIndex++);

            return this.typewrite(element, message, charIndex, this.delay);
          } else {
            if (isFunction(this.beforeChar)) {
              this.beforeChar();
            }

            const timerToType: NodeJS.Timer = setTimeout(() => {
              element.innerHTML = message.substring(0, charIndex++);

              if (isFunction(this.afterChar)) {
                this.afterChar();
              }

              return this.typewrite(element, message, charIndex, this.delay);
            }, delay);

            this.timers.push(timerToType);
          }
        }
      }
    } else {
      if (isFunction(this.onFinish)) {
        const timerToFinish: NodeJS.Timer = setTimeout(
          this.onFinish,
          this.delay
        );

        this.timers.push(timerToFinish);
      }
    }
  }
}
