
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  ButtonPrimary,
  Heading,
  Row,
  BankAccount,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import {
  WithdrawsAPI,
  CreateWithdraw
} from '@/modules/transaction/services/withdraws';
import { GtmTracker } from '@/modules/common/services/analytics';

@Component({
  components: {
    Heading,
    Row,
    BankAccount,
    ButtonPrimary,
    BaseText
  }
})
export default class ConfirmationWithdrawTransaction extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Object, required: true })
  readonly withdraw!: CreateWithdraw;

  private isLoading = false;

  get transferInfo() {
    return 'O valor será enviado para a sua Conta Digital da Warren. Você poderá utilizá-lo para investir, pagar contas ou fazer transferências para outros bancos.';
  }

  private async handleCreateWithdraw() {
    try {
      const withdrawsApi = new WithdrawsAPI();

      this.isLoading = true;
      await withdrawsApi.createWithdrawal(this.withdraw);
      this.track();
      this.$emit('click-finished');
    } catch (error) {
      this.isLoading = false;
      this.$emit('error');
    }
  }

  get withdrawAmount() {
    return money(this.withdraw.amount);
  }

  get withdrawNetValue() {
    return money(this.withdraw.netValue);
  }

  private track() {
    GtmTracker.trackEvent({
      action: 'Withdrawal Created',
      category: '',
      label: ''
    });
  }

  get agencyNumber() {
    return this.withdraw.accountAgency.split('-')[0];
  }
}
