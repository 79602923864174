
import { Component, Prop, Vue, Watch, Emit, Ref } from 'vue-property-decorator';
import { getEnum } from '@/modules/common/helpers/resources';
import { ConversationInputs } from './components/ConversationInputs';
import { ConversationMessages } from './components/ConversationMessages';
import {
  IConversationQuestion,
  IConversationQuestionMessage,
  IConversationQuestionButton,
  IConversationSlotQuestion,
  conversationAnswerDataTypes,
  IConversationQuestionSelectValue
} from '@/modules/conversation/services/conversation';
import { FeatureFlag } from '@/modules/common/helpers/feature-flag';
import { isWhitelabelClient } from '@/modules/common/helpers/whitelabel';

@Component({
  components: {
    ConversationInputs,
    ConversationMessages
  }
})
export default class Conversation extends Vue {
  @Prop({ type: Object })
  public conversationQuestionData!: IConversationQuestion;

  @Prop({ type: Object, required: false })
  public conversationAnswerData?: conversationAnswerDataTypes;

  @Prop({ type: String, required: false })
  public username?: string;

  @Prop({ type: String, required: false })
  public trademarkInitial?: string;

  @Prop({ type: Function, default: () => [] })
  public userMessages!: (
    questionAnswer: string | string[]
  ) => IConversationQuestionMessage[];

  @Prop({ type: String, required: false })
  public userSlotInput?: string | string[];

  @Prop({ type: Array, required: false })
  public questionsWithSlotInputs?: IConversationSlotQuestion[];

  @Prop({ type: Boolean, required: false })
  public showDepositButton?: boolean;

  private currentQuestionWithSlot?: IConversationSlotQuestion;
  private questionWithCardMessages = false;
  private hasRowsInput = false;
  private selectArrayContent: IConversationQuestionSelectValue[] = [];
  private defaultSelectSearchElement?: IConversationQuestionSelectValue;

  private inputButtons: IConversationQuestionButton[] = [];
  private messagesQueue: IConversationQuestionMessage[] = [];
  private answeredQuestions: any = {};

  private isUserAnswering = false;
  private userAnswer: string | string[] = '';

  private cleaningMessages = false;

  private featureFlag = new FeatureFlag();

  @Ref() private readonly messages!: ConversationMessages;

  public clearMessages() {
    this.messagesQueue = [];
    this.messages.clearMessagesHistory();
  }

  @Watch('conversationAnswerData', { deep: true })
  private getRespondedAnswers() {
    if (this.conversationAnswerData) {
      this.answeredQuestions = this.conversationAnswerData.answers;
    }
  }

  @Watch('conversationQuestionData', { immediate: true, deep: true })
  private async getNextConversationMessages() {
    if (this.isUserAnswering) {
      this.isUserAnswering = false;
    }

    if (this.conversationQuestionData) {
      this.questionWithCardMessages = false;

      this.conversationQuestionData.messages.forEach(
        (messageContent: IConversationQuestionMessage) => {
          if (messageContent.type === 'cards') {
            this.questionWithCardMessages = true;
          }
          this.messagesQueue.push(messageContent);
        }
      );

      if (this.questionsWithSlotInputs) {
        this.questionsWithSlotInputs.forEach(question => {
          if (question.id === this.conversationQuestionData.id) {
            this.currentQuestionWithSlot = question;
          }
        });
      }

      const hasSelectInput =
        !!this.conversationQuestionData.selects &&
        this.conversationQuestionData.selects.length !== 0;

      if (hasSelectInput) {
        this.setSelectOptions();
      } else {
        this.defaultSelectSearchElement = undefined;
        this.selectArrayContent = [];
      }

      this.hasRowsInput = !!(
        this.conversationQuestionData.rows &&
        this.conversationQuestionData.rows.length !== 0
      );

      if (!this.questionWithCardMessages) {
        if (
          this.hasRadioOrCheckOrSelectInput ||
          (this.currentQuestionWithSlot &&
            this.currentQuestionWithSlot.hasDefaultAnswerButton)
        ) {
          this.inputButtons = [
            {
              label: {
                title: 'Continuar'
              },
              value: 'selected'
            }
          ];
        } else {
          this.setButtonsAndCheckInvoiceFF();
          this.setDepositButton();
        }
      } else {
        this.inputButtons = [];
      }
    }
  }

  private setDepositButton() {
    if (!this.showDepositButton) {
      this.inputButtons = this.conversationQuestionData.buttons.filter(
        btn => btn.value !== 'deposits'
      );
    }
  }

  private setButtonsAndCheckInvoiceFF() {
    const canUseInvoice = this.featureFlag.isLocalEnabled(
      'TRANSACTION_INVOICE'
    );
    if (!canUseInvoice && isWhitelabelClient()) {
      this.inputButtons = this.conversationQuestionData.buttons.filter(
        btn => btn.value !== 'invoice'
      );
    } else {
      this.inputButtons = this.conversationQuestionData.buttons;
    }
  }

  private setSelectOptions() {
    switch (this.conversationQuestionData.selects![0].type) {
      case 'occupation':
        this.defaultSelectSearchElement = {
          label: {
            title: 'Outros'
          },
          value: '000000'
        };
        Object.entries(getEnum('ProfessionCBO')).forEach(entry => {
          this.selectArrayContent.push({
            label: {
              title: entry[1] as string
            },
            value: entry[0]
          });
        });
        break;
    }
  }

  private messageSelectedInput(value: string | string[]) {
    this.userAnswer = value;

    if (this.userAnswer.length === 0) {
      this.userAnswer = '';
    }

    const isSelectAndUserHasNotChooseAnswer =
      this.conversationQuestionData.selects!.length !== 0 &&
      (value as string[]).length === 0;

    if (isSelectAndUserHasNotChooseAnswer) {
      this.userAnswer = '';
    }

    if (this.questionWithCardMessages || this.hasRowsInput) {
      this.inputAnswered(value);
    }
  }

  @Watch('userSlotInput')
  private userSlotInputAnswerChanged() {
    if (
      this.currentQuestionWithSlot &&
      !this.currentQuestionWithSlot.hasDefaultAnswerButton &&
      this.userSlotInput
    ) {
      this.inputAnswered(this.userSlotInput);
    }
  }

  private async inputAnswered(answer: string | string[]) {
    const isAnsweredBySlotInput: boolean = answer
      ? false
      : !!this.currentQuestionWithSlot;

    if (isAnsweredBySlotInput) {
      if (this.userSlotInput) {
        this.userAnswer = this.userSlotInput;
      }
    } else {
      if (!this.hasRadioOrCheckOrSelectInput) {
        this.userAnswer = answer;
      }
    }

    if (this.userAnswer) {
      this.userMessages(this.userAnswer).forEach(userMessage => {
        if (userMessage.value) {
          this.messagesQueue.push(userMessage);
        }
      });

      this.currentQuestionWithSlot = undefined;
      this.isUserAnswering = false;

      if (this.conversationQuestionData.clearMessages) {
        this.cleaningMessages = true;
        await setTimeout(() => {
          this.clearMessages();
          this.cleaningMessages = false;

          this.responded();
          this.userAnswer = '';
        }, 400);
      } else {
        this.responded();

        this.userAnswer = '';
      }

      this.currentQuestionWithSlot = undefined;
      this.isUserAnswering = false;
    }
  }

  private finishMessagesAndAwaitUserInput() {
    this.isUserAnswering = true;
  }

  public get conversationMessageWithExtraPadding() {
    return (
      this.inputButtons.length > 2 ||
      (this.conversationQuestionData &&
        this.conversationQuestionData.inputs.length > 0 &&
        this.conversationQuestionData.inputs[0].quickAnswers &&
        this.conversationQuestionData.inputs[0].quickAnswers!.length > 0)
    );
  }

  public get hasUserInputs(): boolean {
    return (
      this.conversationQuestionData &&
      (this.conversationQuestionData.inputs.length !== 0 ||
        this.inputButtons.length !== 0)
    );
  }

  private get hasRadioOrCheckOrSelectInput() {
    const hasSelectInput =
      !!this.conversationQuestionData.selects &&
      this.conversationQuestionData.selects.length !== 0;

    return (
      this.conversationQuestionData.radios.length !== 0 ||
      this.conversationQuestionData.checkbox.length !== 0 ||
      hasSelectInput
    );
  }

  @Watch('isUserAnswering')
  @Emit()
  private responding() {
    return this.isUserAnswering;
  }

  @Emit()
  private responded() {
    return {
      value: this.userAnswer,
      messageId: this.conversationQuestionData.id
    };
  }
}
