
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  Heading,
  DataTable,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import { StatementEmpty } from '@/modules/transaction/views/Transactions/components/StatementEmpty';
import { StatementError } from '@/modules/transaction/views/Transactions/components/StatementError';
import { ReceiptModal } from '@/modules/transaction/components/ReceiptModal';

import { columns } from '@/modules/transaction/views/Transactions/components/FutureTransactionsView/helpers/tableHelper';
import { useDashStore } from '@/modules/common/store/DashModule';
import { getUpcomingTransfers } from '@/modules/transaction/services/unified-account';
import { IFiltersValue, IStatementTransactions } from '../StatementList/types';

import * as trackEvents from '@/modules/transaction/views/Transactions/track-events';

@Component({
  components: {
    Heading,
    DataTable,
    StatementEmpty,
    StatementError,
    ReceiptModal,
    ButtonSecondary
  }
})
export default class FutureTransactionsSection extends Vue {
  @Emit('open-future-transactions')
  openFutureTransactions() {
    trackEvents.trackSeeAllFutureMovimentationsClick();
  }

  private readonly dashModule = useDashStore();

  transactionId? = '';
  isLoading = true;
  isReceiptModalOpen = false;
  isEmpty = true;
  hasError = false;
  filters = {
    page: 1,
    itemsPerPage: 4
  };

  get showMoney() {
    return this.dashModule?.showMoney;
  }

  get computedClass() {
    return this.showMoney ? '' : 'hidden-fields';
  }

  get columns() {
    return columns;
  }

  async getFutureTransactions() {
    this.isLoading = true;
    try {
      const data = await getUpcomingTransfers(this.filters);
      this.isEmpty = data.items.length === 0;
      return data.items;
    } catch (e) {
      this.hasError = true;
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  loadError() {
    trackEvents.trackFutureTransactionsErrorLoad();
  }

  changeFilters(data: { hasAppliedFilters: boolean; filters: IFiltersValue }) {
    this.hasError = false;
    this.isEmpty = false;
    this.filters = { ...this.filters, page: 1, ...data.filters };
  }

  handleClick(row: IStatementTransactions) {
    this.changeModalStatus();
    trackEvents.trackSelectMovimentationClick();
    this.transactionId = row.id;
  }

  changeModalStatus() {
    this.isReceiptModalOpen = !this.isReceiptModalOpen;
  }
}
