import { Route, RouteConfig } from 'vue-router';
import { beforeEnterInvestmentFlow } from '@/router/helpers/beforeEnterInvestmentFlow';

import InvestmentTransactionView from '@/modules/transaction/views/InvestmentTransaction/InvestmentTransactionView.vue';
import PortfoliosToInvestView from '@/modules/transaction/views/PortfoliosToInvest/PortfoliosToInvestView.vue';
import NewTransactionsView from '@/modules/transaction/views/NewTransactions/NewTransactionsView.vue';
import NewTransferView from '@/modules/transaction/views/NewTransfer/NewTransferView.vue';
import TransactionsView from '@/modules/transaction/views/Transactions/TransactionsView.vue';
import VariableIncomeInvestment from '@/modules/transaction/views/VariableIncomeInvestment/VariableIncomeInvestment.vue';
import VariableIncomeWithdraw from '@/modules/transaction/views/VariableIncomeWithdraw/VariableIncomeWithdraw.vue';

export const transactionsRoutes: RouteConfig[] = [
  {
    name: 'new-transactions',
    path: '/new-transactions/:portfolio?',
    component: NewTransactionsView,
    meta: {
      context: 'cash'
    }
  },
  {
    name: 'new-transfer',
    path: '/new-transfer',
    component: NewTransferView,
    meta: {
      context: 'transactions'
    }
  },
  {
    name: 'investment-transaction',
    path: '/investment-transaction/:portfolio',
    component: InvestmentTransactionView,
    beforeEnter: beforeEnterInvestmentFlow,
    props: true,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'portfoliosToInvest',
    path: '/portfolios-to-invest',
    beforeEnter: beforeEnterInvestmentFlow,
    component: PortfoliosToInvestView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'transactions',
    path: '/transactions',
    component: TransactionsView,
    props: ({ params }: Route) => ({
      transactionsStep: params.transactionsStep
    }),
    meta: {
      context: 'cash'
    }
  },
  {
    name: 'variableIncomeInvestment',
    path: '/investment/variable-income/:id',
    component: VariableIncomeInvestment,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'variableIncomeWithdraw',
    path: '/withdraw/variable-income/:id',
    component: VariableIncomeWithdraw,
    meta: {
      context: 'wealth'
    }
  }
];
