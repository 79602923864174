
import { Component, Vue } from 'vue-property-decorator';

import { Feature } from '@/modules/common/components/Feature';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { InstrumentAllocation } from './components/InstrumentAllocation';
import { CustomOperationsSection } from './components/CustomOperationsSection';
import { OrdersSection } from './components/OrdersSection';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';

@Component({
  components: {
    CustomOperationsSection,
    Feature,
    InstrumentAllocation,
    OrdersSection
  }
})
export default class InstrumentDetails extends Vue {
  get isCustomOperationsListEnabled() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.ENABLE_CUSTOM_OPERATIONS_LIST
    );
  }

  get FeatureFlags() {
    return FeatureFlags;
  }
}
