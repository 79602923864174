import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RouteMixin extends Vue {
  public goToPath(path: string) {
    window.location.assign(path);
  }

  public goToRoute(to: string, params?: any, options?: any) {
    if (typeof window.goToRoute === 'function') {
      return window.goToRoute(to, params, options);
    }
  }

  public getHref(to: string, params?: any, options?: any) {
    if (typeof (window as any).getHref === 'function') {
      return (window as any).getHref(to, params, options);
    } else {
      return '';
    }
  }

  public getURLParams<ExpectedParams>(decode = false): ExpectedParams | any {
    if (window.location.href.split('?').length === 1) {
      return false;
    }

    const urlRawParameters = window.location.href.split('?')[1].split('&');
    const paramObject: any = {};

    urlRawParameters.forEach(rawParam => {
      const splitParameter = rawParam.split('=');
      paramObject[splitParameter[0]] = decode
        ? decodeURIComponent(splitParameter[1])
        : splitParameter[1];
    });

    return paramObject as ExpectedParams;
  }

  public getRouteLastParam() {
    const splitRoute = window.location.href.split('/');
    let lastParam = splitRoute[splitRoute.length - 1];

    if (lastParam.split('?').length !== 1) {
      lastParam = lastParam.split('?')[0];
    }

    return lastParam;
  }

  public redirectToUrl(
    path = '',
    params?: { [key: string]: string },
    ignoreHost?: boolean
  ) {
    const url = this.getHrefFromPath(path, params || {}, ignoreHost);
    window.location.href = url;
  }

  public getHrefFromPath(
    path = '',
    params?: { [key: string]: string },
    ignoreHost?: boolean
  ) {
    const url = ignoreHost
      ? `${path}`
      : `${window.location.protocol}//${window.location.host}/${path}`;

    return `${url}${params ? this.createParamsFromObject(params) : ''}`;
  }

  public createParamsFromObject(params: { [key: string]: string }) {
    return Object.entries(params)
      .filter(([, paramValue]) => paramValue !== undefined)
      .reduce((paramsString, [paramKey, paramValue], index) => {
        return `${paramsString}${
          index > 0 ? '&' : '?'
        }${paramKey}=${encodeURIComponent(paramValue)}`;
      }, '');
  }

  public goToTransaction(params: string, showDepositButton = true) {
    return this.$router.push({
      path: `/v3/new-transactions/${params}`,
      query: { showDepositButton: String(showDepositButton) }
    });
  }
}
