import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { Deposit, CreateDeposit } from './deposits-types';

export default class DepositsAPI extends BaseHttpClient {
  public getDeposit(id: string): Promise<any> {
    return this.get<any>(`/api/v2/deposits/single/${id}`);
  }

  public cancelDeposit(id: string): Promise<any> {
    return this.put<any>(`/api/v2/deposits/single/${id}/cancel`);
  }

  public sendDepositByEmail(id: string): Promise<any> {
    return this.post<any>(`/api/v2/deposits/single/${id}/send-email`);
  }

  public createDeposit(data: CreateDeposit) {
    return this.post<Deposit>('api/v3/deposits/create', data);
  }
}
