export type ColumnGetterFn = (row: any) => string | number;

export type ColumnGetter = ColumnGetterFn | string;

export interface TableDisplayColumn {
  header: string;
  getter: ColumnGetter;
  align: 'left' | 'center' | 'right';
}

export enum TableDisplaySizes {
  SMALL = `small`,
  MEDIUM = `medium`,
  LARGE = `large`
}
