import { IConversationQuestion } from '@/modules/conversation/services/conversation';
import { dateViewer } from '@/modules/common/helpers/date';

export const parseQuestionData = (question: IConversationQuestion) => {
  if (question.inputs.length) {
    const inputs = question.inputs.map(input => ({
      ...input,
      defaultValue:
        input.mask === 'date' && typeof input.defaultValue === 'string'
          ? dateViewer(input.defaultValue)
          : input.defaultValue
    }));

    return { ...question, inputs };
  }

  return question;
};
