
import { Component, Watch } from 'vue-property-decorator';
import { Money } from 'v-money';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';
import { mixins } from 'vue-class-component';
import { ConversationInputBase } from '../ConversationInputBase';

@Component({
  components: { Money, ButtonPrimary }
})
export default class ConversationCurrencyInput extends mixins(
  ConversationInputBase
) {
  protected overrideInput() {
    if (this.overrideInputValue.indexOf('.') === -1) {
      this.inputValue = `${this.overrideInputValue}00`;
    } else {
      this.inputValue = parseFloat(this.overrideInputValue);
    }
    this.enableSubmitButton();
  }

  @Watch('inputValue')
  private valueAbsolute() {
    if (+this.inputValue < 0) {
      this.inputValue = Math.abs(+this.inputValue).toString();
    }
  }
}
