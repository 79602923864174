
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ICustomer } from '@/types/models';
import {
  Button,
  Heading,
  Row,
  BankAccount,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';

import { DepositsAPI, Deposit } from '@/modules/transaction/services/deposits';
import { moneyViewer as money } from '@/modules/common/helpers/currency';

import { IConversationQuestionTransaction } from '@/modules/conversation/services/conversation';
import { trackEvent } from '@/modules/transaction/views/NewTransactions/track-events';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    Heading,
    Row,
    BankAccount,
    Button,
    LoaderIndicator
  },
  filters: { money }
})
export default class DepositTransaction extends Vue {
  @Prop({ type: Object, required: true })
  private transaction!: IConversationQuestionTransaction;

  private deposit!: Deposit;
  private isLoading = true;

  @customerModule.Getter('getCustomer')
  private customer!: ICustomer;

  private async created() {
    await this.handleCreateDeposit(this.transaction);
    this.isLoading = false;
  }

  private async handleCreateDeposit(data: IConversationQuestionTransaction) {
    try {
      const depositAPI = new DepositsAPI();
      const deposit = await depositAPI.createDeposit({ deposits: { ...data } });

      if (deposit.paymentBankAgency) {
        this.deposit = {
          ...deposit,
          paymentBankAgency: deposit.paymentBankAgency.split('-')[0]
        };
      }

      this.deposit = deposit;
      this.track();
    } catch (error) {
      this.isLoading = false;
      this.$emit('error');
    }
  }

  private get getPortfolioName() {
    if (this.deposit) {
      return this.deposit.transactionsInvest[0].portfolioName;
    }

    return '';
  }

  private track() {
    const isFirstDeposit = this.customer!.depositAcceptedCount === 0;
    trackEvent({
      type: 'TED/DOC',
      amount: this.deposit.amount,
      isFirstDeposit
    });
  }
}
