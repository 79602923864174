
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseText } from '@warrenbrasil/nebraska-web';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    BaseText
  }
})
export default class MonetaryText extends Vue {
  @Prop({ type: String, default: 'lg' })
  readonly size!: string;

  @Prop({ type: String, default: 'p' })
  readonly as!: string;

  @Prop({ type: String, required: true })
  readonly text!: string;

  @Prop({ type: Number, required: true })
  readonly value!: number;

  get color() {
    if (this.value > 0) {
      return NebraskaColors.monetaryPositive;
    } else if (this.value < 0) {
      return NebraskaColors.monetaryNegative;
    }
    return NebraskaColors.textPrimary;
  }
}
