
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { CopyMixin } from '@/modules/common/mixins/copy';
import { namespace } from 'vuex-class';
import { ICustomer } from '@/types/models';

import {
  Heading,
  Row,
  Button,
  BaseIcon,
  LoaderIndicator
} from '@warrenbrasil/nebraska-web';
import { InvoicesAPI, Invoice } from '@/modules/transaction/services/invoices';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import { IConversationQuestionTransaction } from '@/modules/conversation/services/conversation';
import { trackEvent } from '@/modules/transaction/views/NewTransactions/track-events';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    Heading,
    Row,
    LoaderIndicator,
    Button,
    BaseIcon
  },
  filters: { money }
})
export default class DepositInvoinceTransaction extends mixins(CopyMixin) {
  private NebraskaColors = NebraskaColors;
  private isLoading = false;
  private isCopied = false;
  private invoice: Invoice | null = null;
  private invoicesApi = new InvoicesAPI();

  @customerModule.Getter('getCustomer')
  private customer!: ICustomer;

  @Prop({ type: Object, required: true })
  private transaction!: IConversationQuestionTransaction;

  private get getPortfolioName() {
    return this.invoice ? this.invoice.transactionsInvest[0].portfolioName : '';
  }

  private get getPortfolioAmount() {
    return this.invoice ? this.invoice.transactionsInvest[0].amount : '';
  }

  private get getCopyLabel() {
    return this.isCopied ? 'Copiado!' : 'Copiar Números';
  }

  private async created() {
    this.isLoading = true;
    await this.createInvoice(this.transaction);
    this.trackInvoice();
    await this.updateInvoice();
  }

  private isInvoiceReady(invoice: Invoice) {
    return !invoice.isProcessing;
  }

  private async updateInvoice() {
    try {
      const { deposit } = await this.invoicesApi.getInvoice(this.invoice!._id);

      if (this.isInvoiceReady(deposit)) {
        this.invoice = deposit;
        this.trackInvoice();
        this.isLoading = false;
      } else {
        setTimeout(() => {
          this.updateInvoice();
        }, 2000);
      }
    } catch {
      this.isLoading = false;
      this.$emit('error');
    }
  }

  private async createInvoice(data: IConversationQuestionTransaction) {
    await this.invoicesApi
      .createInvoice(data)
      .then((invoice: Invoice) => {
        this.invoice = invoice;
      })
      .catch(() => {
        this.$emit('error');
      });
  }

  private onCopy(e: Event) {
    e.preventDefault();

    if (!this.isCopied) {
      this.copyToClipboard(this.invoice!.barCodeString);
      this.isCopied = true;
    }
  }

  private trackInvoice() {
    trackEvent({
      type: 'invoice',
      amount: this.invoice!.amount,
      isFirstDeposit: this.customer!.depositAcceptedCount === 0
    });
  }
}
