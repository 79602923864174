
import { Component, Vue } from 'vue-property-decorator';
import {
  Heading,
  DataTable,
  DataTableProps,
  Pagination
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { StatementAvatarCell } from '@/modules/transaction/views/Transactions/components/StatementAvatarCell';
import { StatementFilters } from './components/StatementFilters';
import { StatementFiltersLoader } from './components/StatementFiltersLoader';
import { StatementError } from '@/modules/transaction/views/Transactions/components/StatementError';
import { StatementEmpty } from '@/modules/transaction/views/Transactions/components/StatementEmpty';
import { ReceiptModal } from '@/modules/transaction/components/ReceiptModal';
import {
  getStatementList,
  IStatementPagination
} from '@/modules/transaction/services/unified-account';
import { EDirection } from '@/modules/transaction/services/unified-account/enums';
import { IStatementTransactions, IFilters, IFiltersValue } from './types';
import { mountTable } from './helpers';
import { useDashStore } from '@/modules/common/store/DashModule';
import * as trackEvents from '@/modules/transaction/views/Transactions/track-events';
import { getTransactionsReport } from '@/modules/transaction/services/banking';
import { forceFileDownload } from '@/modules/common/helpers/force-download';

@Component({
  components: {
    Heading,
    DataTable,
    StatementFilters,
    StatementFiltersLoader,
    Pagination,
    StatementError,
    StatementEmpty,
    StatementAvatarCell,
    ReceiptModal
  }
})
export default class StatementList extends Vue {
  private readonly dashModule = useDashStore();

  private isLoading = false;

  hasAppliedFilters = false;
  transactionId? = '';
  isReceiptModalOpen = false;
  isEmpty = false;
  hasError = false;
  isFetchingDownload = false;
  filtersOptions: Reactive<IFilters | undefined> = null;
  pagination: IStatementPagination | null = null;
  filters = {
    page: 1
  };

  get isDisabled() {
    return this.isLoading || (this.isEmpty && !this.hasAppliedFilters);
  }

  get showPagination() {
    return this.pagination && !this.isLoading && this.pagination.totalPages > 1;
  }

  get showMoney() {
    return this.dashModule?.showMoney;
  }

  get computedClass() {
    return this.showMoney ? '' : 'hidden-fields';
  }

  get columns(): DataTableProps.Column[] {
    return [
      {
        width: '4.11%',
        component: StatementAvatarCell,
        getter: ({ icon, direction }) => ({
          iconName: icon,
          type: direction
        })
      },
      {
        label: 'Movimentação',
        width: '46.25%',
        getter: ({ name, description }) => ({
          text: name,
          description
        })
      },
      {
        label: 'Data',
        width: '23.11%',
        align: DataTableProps.Align.Right,
        getter: 'date'
      },
      {
        label: 'Valor',
        width: '23.11%',
        align: DataTableProps.Align.Right,
        getter: ({ value, direction }) => ({
          text: value.formatted,
          color: this.getColor(direction)
        })
      },
      {
        width: '3.42%',
        type: 'icon',
        getter: () => ({
          icon: 'EA0170',
          ariaLabel: 'Detalhes da transação'
        })
      }
    ];
  }

  private getColor(type: EDirection) {
    return type === EDirection.Credit
      ? NebraskaColors.monetaryPositive
      : NebraskaColors.textPrimary;
  }

  async getStatementList() {
    this.hasError = false;
    this.isLoading = true;
    try {
      const data = await getStatementList(this.filters);
      this.pagination = data.pagination;
      this.filtersOptions = data.filters;
      this.isEmpty = data.items.length === 0;
      return mountTable(data.items);
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  loadError() {
    trackEvents.trackTransactionsErrorLoad();
  }

  handleFiltersChange(data: {
    hasAppliedFilters: boolean;
    filters: IFiltersValue;
  }) {
    this.isEmpty = false;
    this.filters = { ...this.filters, page: 1, ...data.filters };
    this.hasAppliedFilters = data.hasAppliedFilters;
  }

  changePage(value: number) {
    this.filters = { ...this.filters, page: value };
  }

  handleClick(row: IStatementTransactions) {
    trackEvents.trackSelectMovimentationClick();
    this.changeModalStatus();
    this.transactionId = row.id;
  }

  changeModalStatus() {
    this.isReceiptModalOpen = !this.isReceiptModalOpen;
  }

  async statementDownload(filters: IFiltersValue) {
    this.isFetchingDownload = true;
    try {
      const data = await getTransactionsReport(filters);
      forceFileDownload({
        data,
        type: 'application/pdf',
        fileName: `Warren_ExtratoConta.pdf`
      });
    } finally {
      this.isFetchingDownload = false;
    }
  }
}
