
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { BaseCard } from '@/modules/common/components/cards/BaseCard';
import { BaseBadge } from '@/modules/common/components/badges/BaseBadge';
import { Columns } from '@/modules/common/components/__deprecated__/Columns';
import { IConversationQuestionMessageCard } from '@/modules/conversation/services/conversation';
import { BaseText } from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: { BaseCard, BaseBadge, Columns, BaseText }
})
export default class ConversationCardMessage extends Vue {
  @Prop(Array)
  public cards!: IConversationQuestionMessageCard[];

  @Emit()
  private selected(value: string) {
    return value;
  }

  readonly NebraskaColors = NebraskaColors;
}
