import { number, object, string, ValidationError } from 'yup';

const schema = object().shape({
  name: string().required(),
  email: string().required(),
  password: string().min(6).max(128),
  date: string().required().length(10),
  integer: number().required(),
  currency: number().required(),
  text: string().required()
});

interface SignUpErrors {
  [key: string]: string[];
}

function getErrors(errors: ValidationError): SignUpErrors {
  const { inner } = errors;

  return inner.reduce((acc: SignUpErrors, err) => {
    const { path, errors: validationErrors } = err;

    acc[path] = validationErrors;

    return acc;
  }, {});
}

export default async function validate(
  data: any
): Promise<boolean | SignUpErrors> {
  try {
    // @ts-ignore
    return await schema.validate(data, { abortEarly: false });
  } catch (validationError) {
    const errors = getErrors(validationError);

    return errors;
  }
}
