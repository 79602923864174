
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { RouteMixin } from '@/modules/common/mixins/route';

import {
  BaseIcon,
  Row,
  Heading,
  SpinnerLoader,
  HelperViewStatus,
  InitialAvatarIcon,
  ContentDisplay,
  ListDisplay,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import { Money } from '@/modules/common/components/Money';
import {
  NavigationPanelWrapper,
  NavigationPanel
} from '@/modules/common/components/NavigationPanel';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { IPortfolioList } from '@/types/models/Portfolio';

import { PortfoliosList } from '@/modules/wealth/views/Wealth/components/PortfoliosList';
import { AllocationDetail } from '@/modules/wealth/views/Wealth/components/AllocationDetail';
import { Feature } from '@/modules/common/components/Feature';
import { WealthStatement } from '@/modules/wealth/components/WealthStatement';
import { FeatureFlags } from '@/types/models/FeatureFlags';

import {
  trackAllocationEvent,
  trackFAQEvent,
  trackGoToBuilder
} from './track-events';
import { WealthSummary } from '@/modules/wealth/components/WealthSummary';
import {
  getProductAllocations,
  IProductAllocationsResponse
} from '@/modules/wealth/services/investment';

import { getCashSummary } from '@/modules/home/services/bff';

@Component({
  components: {
    WealthSummary,
    BackButton,
    BaseIcon,
    Heading,
    PortfoliosList,
    NavigationPanel,
    NavigationPanelWrapper,
    Row,
    WealthStatement,
    Feature,
    AllocationDetail,
    SpinnerLoader,
    HelperViewStatus,
    InitialAvatarIcon,
    ContentDisplay,
    ListDisplay,
    Money,
    SkeletonLoader
  }
})
export default class WealthContent extends mixins(RouteMixin) {
  @Prop({ type: Array, required: true })
  private readonly portfolios!: IPortfolioList[];

  @Prop({ type: Boolean, default: true })
  private readonly showCreateWalletButton!: boolean;

  isProductAllocationsLoading = false;
  hasProductAllocationsError = false;

  private productAllocations: IProductAllocationsResponse | null = null;

  isAccountAmountLoading = false;
  hasAccountAmountError = false;

  accountAmount = '';

  private get FeatureFlags() {
    return FeatureFlags;
  }

  async getProductAllocation() {
    this.hasProductAllocationsError = false;
    this.isProductAllocationsLoading = true;
    try {
      this.productAllocations = await getProductAllocations();
    } catch (error) {
      console.error(error);
      this.hasProductAllocationsError = true;
    } finally {
      this.isProductAllocationsLoading = false;
    }
  }

  async fetchAccountAmount() {
    try {
      this.isAccountAmountLoading = true;
      this.hasAccountAmountError = false;

      const data = await getCashSummary();

      this.accountAmount = data.cashSection.summary.accountAmount.formatted;
    } catch {
      this.hasAccountAmountError = true;
    } finally {
      this.isAccountAmountLoading = false;
    }
  }

  private async created() {
    await Promise.all([this.getProductAllocation(), this.fetchAccountAmount()]);
  }

  private goToBuilder() {
    trackGoToBuilder();

    this.$router.push({ name: 'catalog' });
  }

  private trackEvent() {
    trackAllocationEvent();
  }

  private trackFaq() {
    trackFAQEvent();
  }
}
