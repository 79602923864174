
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Row } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Row
  }
})
export default class ConversationTableMessage extends Vue {
  @Prop({ type: Array, default: [] })
  public table!: [];

  private get parseTable() {
    const parsed: string[][] = this.table.flat();

    return parsed.map((item: string[]) => ({
      title: item[0] || '',
      value: item[1] || ''
    }));
  }
}
