
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IBankAccount } from '@/types/models';

import { FormField } from '@/modules/common/components/__deprecated__/FormField';
import { Radio } from '@/modules/common/components/__deprecated__/Radio';
import { Heading } from '@warrenbrasil/nebraska-web';

import { getString, getEnum } from '@/modules/common/helpers/resources';
import { YupValidationErrors } from '@/modules/common/helpers/yup-validation';
import { TheMask } from 'vue-the-mask';

import BBIcon from '@/assets/imgs/banks/bancodobrasil.svg';
import NubankIcon from '@/assets/imgs/banks/nubank.svg';
import BradescoIcon from '@/assets/imgs/banks/bradesco.svg';
import CaixaIcon from '@/assets/imgs/banks/caixa.svg';
import ItauIcon from '@/assets/imgs/banks/itau.svg';
import InterIcon from '@/assets/imgs/banks/inter.svg';
import SantanderIcon from '@/assets/imgs/banks/santander.svg';

type BankAccount = Partial<IBankAccount>;

function getBankTemplate(): BankAccount {
  return {
    bank: '001',
    agency: '',
    number: '',
    digit: '',
    type: 'current',
    hasSecondHolder: false,
    firstHolderCPF: '',
    firstHolderName: '',
    _id: ''
  };
}

@Component({
  components: {
    FormField,
    TheMask,
    Radio,
    Heading
  }
})
export default class BankAccounts extends Vue {
  /** Bank accounts to edit. */
  @Prop({ type: Array, default: () => [] })
  private bankAccounts!: BankAccount[];

  /** Errors to be displayed, if any. */
  @Prop({ type: Object, default: () => ({}) })
  private errors!: YupValidationErrors;

  @Prop({ type: Boolean, default: true })
  private appendEmptyBankAccount!: boolean;

  @Prop({ type: Boolean, default: false })
  private showLabelToSavingAccount!: boolean;

  @Prop({ type: Boolean, default: false })
  private disableAddBank?: boolean;

  private editingBankAccounts: BankAccount[] = [];

  private getString = getString;

  private bankCodes: { [key: string]: string } = getEnum('Bank');

  private quickSelectableBanks = [
    { id: '001', icon: BBIcon },
    { id: '260', icon: NubankIcon },
    { id: '237', icon: BradescoIcon },
    { id: '104', icon: CaixaIcon },
    { id: '341', icon: ItauIcon },
    { id: '077', icon: InterIcon },
    { id: '033', icon: SantanderIcon }
  ];

  private mounted() {
    if (this.appendEmptyBankAccount && !this.bankAccounts.length) {
      this.addNewBank();
    }
  }

  private onlyCurrentType(bank: BankAccount) {
    const nubankCode = '260';
    const interCode = '077';

    const onlyHasCurrent = bank.bank === nubankCode || bank.bank === interCode;

    if (onlyHasCurrent) {
      bank.type = 'current';
    }

    return onlyHasCurrent;
  }

  @Watch('bankAccounts', { deep: true, immediate: true })
  private onChangeBankAccounts() {
    if (this.bankAccounts.length) {
      this.editingBankAccounts = this.bankAccounts;
    }
  }

  @Watch('editingBankAccounts', { deep: true })
  private onEditingBankAccountsChange() {
    /**
     * Change event. Triggered whenever properties of any bank accounts change.
     *
     * @type {BankAccount[]}
     */
    this.$emit('change', this.editingBankAccounts);
  }

  private addNewBank() {
    this.editingBankAccounts.push(getBankTemplate());
  }

  private get getBankCodes() {
    return Object.keys(this.bankCodes)
      .sort()
      .map(bankCode => ({ code: bankCode, name: this.bankCodes[bankCode] }));
  }

  private removeBank(index: number) {
    const removed = this.editingBankAccounts.splice(index, 1)[0];
    this.$emit('deleted', removed);
  }

  private labelToSavingAccount() {
    const caixaBankCode = '104';
    const isSavings = this.editingBankAccounts.filter(account => {
      return account.bank === caixaBankCode && account.type === 'savings';
    });

    if (isSavings.length) {
      return (this.showLabelToSavingAccount = true);
    }

    return (this.showLabelToSavingAccount = false);
  }

  private getValidationError(path: string): string {
    const err = this.errors[path];
    if (err) {
      return err[0];
    }

    return '';
  }
}
