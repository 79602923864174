
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import {
  ContentDisplay,
  HeadingDisplay,
  ListDisplay
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { InstrumentAvatarFallback } from '@/modules/trade/components/InstrumentAvatarFallback';
import { TableDisplay } from '@/modules/common/components/TableDisplay';

import {
  CustomOperation,
  CustomOperationStatus,
  MonitoringBarrier
} from '@/modules/trade/services/custom-operations';
import { getCurrencyTextColor } from '@/modules/common/helpers/currency';
import { InstrumentType } from '@/modules/trade/types';
import { CustomOperationSteps } from '../../types';
import { getInstrumentSymbolPreffix } from '@/modules/trade/helpers/instruments';
import { trackCustomOperationTooltipClick } from '../../trackers';

@Component({
  components: {
    HeadingDisplay,
    ContentDisplay,
    ListDisplay,
    TableDisplay,
    InstrumentAvatarFallback
  }
})
export default class CustomOperationDetails extends Vue {
  @Prop({ type: Object, required: true })
  readonly operation!: CustomOperation;

  @Emit('change-step')
  emitChangeStep(_step: CustomOperationSteps) {}

  readonly NebraskaColors = NebraskaColors;
  readonly getCurrencyTextColor = getCurrencyTextColor;
  readonly CustomOperationSteps = CustomOperationSteps;

  readonly monitoringBarriersColumns = [
    {
      getter: 'type',
      header: 'Tipo'
    },
    {
      getter: (monitoringBarrier: MonitoringBarrier) => {
        return monitoringBarrier.level.formatted;
      },
      header: 'Nível',
      align: 'right'
    },
    {
      getter: (monitoringBarrier: MonitoringBarrier) => {
        return monitoringBarrier.distance.formatted;
      },
      header: 'Distância',
      align: 'right'
    }
  ];

  get tagLabel() {
    return this.operation.status.value === CustomOperationStatus.Settlement
      ? this.operation.status.formatted
      : this.operation.countdownInDays.formatted;
  }

  get instrumentType() {
    return this.operation.instrument.type?.value || InstrumentType.Stock;
  }

  get instrumentSymbolPreffix() {
    return getInstrumentSymbolPreffix(this.operation.instrument.symbol);
  }

  get hasStrikeOptions() {
    return (
      this.operation.strikeOptions && this.operation.strikeOptions?.length > 0
    );
  }

  get hasMonitoringBarriers() {
    return this.operation.monitoring && this.operation.monitoring?.length > 0;
  }

  handleTooltipClick(step: CustomOperationSteps, label: string) {
    trackCustomOperationTooltipClick(label);
    this.emitChangeStep(step);
  }
}
