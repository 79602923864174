
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { IConversationQuestionButton } from '@/modules/conversation/services/conversation';
import { ButtonPrimary } from '@warrenbrasil/nebraska-web';

@Component({
  components: { ButtonPrimary }
})
export default class ConversationButtonInput extends Vue {
  @Prop(Array)
  public buttons!: IConversationQuestionButton[];

  @Prop(Boolean)
  public isDisabled!: boolean;

  @Prop(Boolean)
  public isProcessing!: boolean;

  private async selectedButton(button: IConversationQuestionButton) {
    this.answered(button.value);
  }

  @Emit()
  private answered(value: string) {
    return value;
  }
}
