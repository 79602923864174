import { getString } from '@/modules/common/helpers/resources';
import { isValid as isValidCPF } from 'cpf';

import { object, string, array, boolean } from 'yup';

import { isFullName } from '@/modules/common/helpers/name';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

const bankSchema = object().shape({
  bank: string().max(3, 'Escolhe o seu banco').required('Defina o banco'),

  agency: string().required('Defina a agência'),

  number: string().required('Defina a conta'),

  digit: string().required('Defina o dígito'),

  type: string().required('Defina o tipo'),

  hasSecondHolder: boolean().nullable(),

  firstHolderCPF: string()
    .nullable()
    .when('hasSecondHolder', {
      is: true,
      then: string()
        .test({
          test: isValidCPF,
          message: getString('common_field_cpf_error_invalid')
        })
        .required(getString('common_field_cpf_error_required')),
      otherwise: string()
    }),

  firstHolderName: string()
    .nullable()
    .when('hasSecondHolder', {
      is: true,
      then: string().test({
        test: isFullName,
        message: getString('common_field_fullname_error_invalid')
      }),
      otherwise: string()
    })
});

const schema = array().of(bankSchema);

export default function getValidator() {
  return new YupValidation(schema);
}
