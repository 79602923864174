
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IConversationQuestionCheckbox } from '@/modules/conversation/services/conversation';
import { Checkbox } from '@/modules/common/components/__deprecated__/Checkbox';
import { Columns } from '@/modules/common/components/__deprecated__/Columns';

@Component({
  components: { Checkbox, Columns }
})
export default class ConversationCheckboxMessage extends Vue {
  @Prop(Array)
  public checkbox!: IConversationQuestionCheckbox[];

  private selectedValues: string[] = [];

  private selectCheckbox(
    isChecked: boolean,
    checkbox: IConversationQuestionCheckbox
  ) {
    if (isChecked) {
      this.selectedValues.push(checkbox.value);
    } else {
      this.selectedValues = this.selectedValues.filter(
        value => value !== checkbox.value
      );
    }

    this.selected();
  }

  private isCheckboxSelected(checkboxValue: string): boolean {
    return this.selectedValues.includes(checkboxValue);
  }

  private get shouldUseGridDisplay() {
    return this.checkbox.length > 5;
  }

  @Emit()
  private selected() {
    return this.selectedValues;
  }
}
