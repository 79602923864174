
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';

import { HelperView } from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    HelperView
  }
})
export default class CustomOperationError extends Vue {
  @Prop({
    type: String,
    default: 'Tivemos um problema ao carregar as informações.'
  })
  readonly errorMessage!: string;

  @Emit('on-retry')
  emitOnRetry() {}
}
