
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  model: {
    // https://vuejs.org/v2/api/#model
    prop: 'model'
  }
})
export default class Radio extends Vue {
  @Prop({ type: [String, Boolean], default: 'on' })
  private value!: string | boolean;

  @Prop({ type: String, default: '' })
  private id!: string;

  @Prop({ type: String, default: '' })
  private name!: string;

  /** Custom Radio Label Padding Right-Left */
  @Prop({ type: String, default: '' })
  private paddingX!: string;

  /** Custom Radio Label Padding Top-Bottom */
  @Prop({ type: String, default: '' })
  private paddingY!: string;

  /** Custom Radio Label Font Size and Line Height */
  @Prop({ type: String, default: '' })
  private font!: string;

  /** Custom Radio Label Margin Bottom */
  @Prop({ type: String, default: '' })
  private marginBottom!: string;

  /** Custom model to handle if radio is checked */
  @Prop({ type: [String, Boolean], default: '' })
  private model!: string | boolean;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private roundedBorder!: boolean;

  @Prop({ type: Boolean, default: true })
  private hasBorderBottom!: boolean;

  @Prop({ type: Boolean, default: false })
  private required!: boolean;

  private get computedClassName() {
    return [this.paddingX, this.paddingY, this.font, this.marginBottom];
  }
}
