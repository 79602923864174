
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

import { BaseModal } from '@warrenbrasil/nebraska-web';
import { CustomOperationDetails } from './components/CustomOperationDetails';
import { CustomOperationLoading } from './components/CustomOperationLoading';
import { CustomOperationError } from './components/CustomOperationError';
import { CustomOperationHelperText } from './components/CustomOperationHelperText';
import { CustomOperationNavBar } from './components/CustomOperationNavBar';
import { ModalStepTransition } from '@/modules/common/components/ModalStepTransition';

import {
  trackCustomOperationModalRetryButtonClick,
  trackCustomOperationModalLoad
} from './trackers';

import {
  CustomOperation,
  getCustomOperationDetails
} from '@/modules/trade/services/custom-operations';

import { CustomOperationSteps } from './types';
import { isBFFError } from '@/modules/common/helpers/bff';
import {
  addBodyScrollWithScrollTo,
  removeBodyScroll
} from '@/modules/common/helpers/browser';
import { HelperTexts } from './data';

@Component({
  components: {
    CustomOperationNavBar,
    CustomOperationDetails,
    CustomOperationError,
    CustomOperationLoading,
    CustomOperationHelperText,
    BaseModal,
    ModalStepTransition
  }
})
export default class CustomOperationModal extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Watch('isOpen', { immediate: true })
  public toggleBodyScroll(isOpen: boolean) {
    if (isOpen) {
      this.fetchCustomOperationDetails();
      removeBodyScroll();
    } else {
      if (this.abortSignal) {
        this.abortSignal.abort();
      }
      addBodyScrollWithScrollTo();
    }
  }

  @Emit('close')
  emitClose() {
    this.currentStep = CustomOperationSteps.Loading;
  }

  operation: CustomOperation | null = null;
  errorMessage = '';
  currentStep = CustomOperationSteps.Loading;
  abortSignal: AbortController | null = null;

  readonly CustomOperationSteps = CustomOperationSteps;
  readonly HelperTexts = HelperTexts;

  get showBackButton() {
    return (
      this.currentStep === CustomOperationSteps.InstrumentVariationHelperText ||
      this.currentStep === CustomOperationSteps.OperationVariationHelperText ||
      this.currentStep === CustomOperationSteps.TotalEarningsHelperText
    );
  }

  async fetchCustomOperationDetails() {
    trackCustomOperationModalLoad();
    try {
      this.currentStep = CustomOperationSteps.Loading;
      this.abortSignal = new AbortController();

      const operation = await getCustomOperationDetails(
        this.id,
        this.abortSignal.signal
      );

      this.operation = operation;
      this.currentStep = CustomOperationSteps.OperationDetails;
    } catch (e) {
      let errorMessage = 'Tivemos um problema ao carregar as informações.';
      if (isBFFError(e)) {
        errorMessage = e.error.message.formatted;
      }
      this.errorMessage = errorMessage;
      this.currentStep = CustomOperationSteps.Error;
    }
  }

  async handleOnRetry() {
    trackCustomOperationModalRetryButtonClick();
    await this.fetchCustomOperationDetails();
  }

  handleChangeStep(step: CustomOperationSteps) {
    this.currentStep = step;
  }
}
