export enum CustomOperationsListOptions {
  TotalEarnings = 'TotalEarnings',
  Quantity = 'Quantity',
  TotalFinancialResult = 'TotalFinancialResult'
}

export interface CustomOperationsOption {
  value: CustomOperationsListOptions;
  label: string;
}
